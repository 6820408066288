import { useState } from "react";
import { connect, useDispatch } from "react-redux";

import { addItem } from "../../actions/cart";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Button from "@mui/material/Button";


function TempCartToCartButton(props) {

  const { tempCart } = props
  const [ addingToCart, setAddingToCart ] = useState(false)
  const dispatch = useDispatch()

  async function handleClick() {
    setAddingToCart(true)
    Object.keys(tempCart.items).forEach((id) => {
      if (tempCart.items[id].sku) {
        dispatch(addItem(tempCart.items[id].sku, tempCart.quantity))
      }
    })
    await new Promise(r => {
      setTimeout(r, 2000);
      window.scrollTo(0, 0);
    });
    setAddingToCart(false);
  }

  function isButtonEnabled() {
    const rx = /^[1-9]\d*$/
    const quantity = tempCart.quantity
    return !rx.test(quantity)
  }

  if (addingToCart) {
    return(
      <Button
        id='addToCart'
        disabled={true}
        variant="contained"
        style={{ width: "100%" }}
        disableElevation
        onClick={(e) => handleClick()}
      >
       カートに追加しました
      </Button>
    )
  } else {
    return(
      <Button
        id='addToCart'
        // disabled={buttonName === 'カートに追加' ? false : true}
        disabled={isButtonEnabled()}
        variant="contained"
        startIcon={<AddShoppingCartIcon />}
        style={{ width: "100%" }}
        disableElevation
        onClick={(e) => handleClick()}
      >
       カートに追加
      </Button>
    )
  }
}


function mapStateToProps({ tempCart }) {
  return {
    tempCart: tempCart
  };
}

export default connect(mapStateToProps)(TempCartToCartButton);
