import Typography from "@mui/material/Typography";
import Skeleton from '@mui/material/Skeleton';


function CartTotalPriceSkeleton() {
  return (
    <Typography>
      <Skeleton animation="wave" variant="rectangular" height={24} style={{width: "100%"}}/>
    </Typography>
  )
}


export default CartTotalPriceSkeleton;
