import { combineReducers } from "redux";
import cart from "./cart";
import config from "./config";
import items from "./items";
import tempCart from "./tempCart";
import search from "./search";


export default combineReducers({
  cart,
  config,
  items,
  tempCart,
  search
});
