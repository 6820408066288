import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { redirect, useSearchParams } from 'react-router-dom';

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";
import ItemCardVertical from "../ItemCardVertical";
import ItemCardVerticalSkeleton from "../ItemCardVerticalSkeleton";
import SearchNavigationBar from "./SearchNavigationBar";
import SearchPaginationBar from "./SearchPaginationBar";

import { API } from 'aws-amplify'

import {
  receiveSearchResultItems,
  receiveSearchKeyword,
  removeSearchResultItems } from "../../actions/search"
import { listItems } from "../../graphql/queries"


function Items(props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { search } = props

  const limit = search.limit
  const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1
  const itemsToDisplay = search.items.slice((page - 1) * limit, page * limit) // slice takes care of out of bound integers

  const [loading, setLoading] = useState(false);
  const drawerWidth = 240;
  const links = {
    0: {
      name: {
        en: "Product List",
        ja: "商品一覧"
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const keyword = searchParams.get("keyword")
    let page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1
    let filter = {}

    // console.log(keyword, search.keyword, search.total)
    if (keyword !== search.keyword || (keyword === null && search.total === 0)) {
      dispatch(removeSearchResultItems())
      setLoading(true)

      const variables = {
        limit: 1500
      }

      // If keyword exists, then build filter for GraphQL
      if (keyword) {
        const keywords = keyword.toLowerCase().split(' ')
        // console.log(keywords)
        if (keywords.length > 1) {
          const andExpression = []
          keywords.forEach((keyword) => {
            andExpression.push({searchableText: {contains: encodeURI(keyword)}})
          })
          filter = {
            and: andExpression
          }
        }
        else {
          filter = {
            searchableText: {
              contains: encodeURI(keyword.toLowerCase())
            }
          }
        }
        variables.filter = filter
      }
      // console.log(variables)

      API.graphql({
        query: listItems, variables: variables
      })
        .then((result) => {
          // console.log(result)
          if (result.data.listItems.items) {

            const items = result.data.listItems.items

            const nextToken = result.data.listItems.nextToken ? result.data.listItems.nextToken : null
            const total = result.data.listItems.items.length

            // sort result by searchResultOrder and then sku
            items.sort((a, b) => {
              if (a.searchResultOrder !== b.searchResultOrder) {
                return a.searchResultOrder - b.searchResultOrder;
              } else {
                return a.sku.localeCompare(b.sku);
              }
            });

            dispatch(receiveSearchResultItems(items, nextToken, page, total))
            dispatch(receiveSearchKeyword(keyword))

            // Check if page is not out of range.
            // If page is out of range, then set page to 0
            // console.log(items.length, search.limit * page, search.page)
            if (items.length < search.limit * page && page !== 1) {
              setSearchParams({ page: 1, keyword: searchParams.get('keyword')})
              return redirect(`?page=1&keyword=${keyword}`)
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [dispatch, search.keyword, search.limit, search.total, setSearchParams, searchParams]); // [] is to trigger only once

  return(
    <Box>
      <Container maxWidth="lg">
        <Header />
      </Container>
      <CssBaseline />
      <Navigation links={links} />
      <Container maxWidth="lg">
        <SearchNavigationBar />
        <Box sx={{ display: "flex"}}>
          <Box
            sx={{
              flexGrow: 1,
              width: { sm: `calc(100% - ${drawerWidth}px)` }
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
            >
              {itemsToDisplay &&
                Object.keys(itemsToDisplay).map((key) => {
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={key} elevation={0}>
                      <ItemCardVertical item={itemsToDisplay[key]} />
                    </Grid>
                  );
              })}
              {loading &&
                [...Array(9).keys()].map((key) => {
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={key} elevation={0}>
                      <ItemCardVerticalSkeleton />
                    </Grid>
                  );
              })}
            </Grid>
          </Box>
        </Box>
        <Box py={4}>
          <SearchPaginationBar/>
        </Box>
        <Box sx={{ pb: 2 }}/>
      </Container>
      <Divider />
      <Container maxWidth="lg">
        <Footer/>
      </Container>
    </Box>
  )
}

function mapStateToProps({ search }) {
  return {
    search: search
  };
}

export default connect(mapStateToProps)(Items);