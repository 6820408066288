import React, {useState} from 'react';

import { connect } from "react-redux";

import { Link as RouterLink } from "react-router-dom";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Skeleton from '@mui/material/Skeleton';
import Typography from "@mui/material/Typography";


function ItemCardVertical(props) {
  const [isLoaded, setIsLoaded] = useState(false);

  const { item, config } = props;

  const language = config.language

  const skuUpperCase = item.sku.toUpperCase()
  const skuLowerCase = item.sku.toLowerCase()
  const name = item.names[language]
  const imageUrl = item.images.frontShadow ? item.images.frontShadow : 'https://openkanal.s3.ap-northeast-1.amazonaws.com/public/common/no-image.png'
  const imageDescription = item.images.frontShadow ? `${skuUpperCase} with front shadow` : 'No image'
  const price = item.price.toLocaleString("ja-JP")

  return (
    <Card>
      {!isLoaded && (
        <Skeleton variant="rectangular" height={170} width={'100%'}>
          <CardMedia component="img"
            alt={imageDescription}
            image={imageUrl}
            onLoad={() => setIsLoaded(true)}/>
        </Skeleton>
      )}
      {isLoaded && (
        <CardMedia component="img"
          alt={imageDescription}
          image={imageUrl} />
      )}
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            {/* <Typography gutterBottom component="div" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> */}
            <Typography gutterBottom component="div" sx={{ whiteSpace: { sm: 'nowrap'}, overflow: { sm: 'hidden'}, textOverflow: { sm: 'ellipsis'} }}>
              { name }
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom component="div" style={{ fontFamily: 'DIN Alternate', textAlign: 'right', fontSize: '1.1rem' }}>
              { price }
              <span style={{ fontSize: '12px' }}>円 (税込)</span>
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: { sm: 'nowrap'}, overflow: { sm: 'hidden'}, textOverflow: { sm: 'ellipsis'} }}>
          SKU: {skuUpperCase}
        </Typography>
      </CardContent>
      <CardActions sx={{ px: 2 }}>
        <Button
          component={RouterLink}
          to={`/item/${skuLowerCase}`}
          size="medium"
          variant="contained"
          disableElevation
          style={{
            width: "100%",
            backgroundColor: "rgb(247, 250, 255)",
            color: "#377dff"
          }}
        >
          詳細
        </Button>
      </CardActions>
    </Card>
  );
}

function mapStateToProps({ config }) {
  return {
    config: config
  };
}

export default connect(mapStateToProps)(ItemCardVertical);
