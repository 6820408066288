import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import Footer from "../Footer/Footer";
import Header from "../Header";
import NavigationNoBackgroundColor from "../NavigationNoBackgroundColor";


function ContactComplete(props) {

  const pageName = {
    en: "",
    ja: "お問い合わせ完了"
  };

  return (
    <Box>
      <Container maxWidth="lg">
        <Header />
      </Container>
      <CssBaseline />
      <NavigationNoBackgroundColor pageName={pageName} />
      <Container maxWidth="lg">
        <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
          <Typography style={{ color: "rgb(30, 32, 34)" }} py={2}>
            お問い合わせが完了しました。
          </Typography>
          <Typography style={{ color: "rgb(103, 119, 136)" }}>
            受付番号は {props.refID} となります。お問い合わせの際は受付番号をお伝えください。
          </Typography>
          <Box py={4}>
            <Button
              component={RouterLink}
              to={`/`}
              variant="contained"
              disableElevation
            >
              商品一覧
            </Button>
          </Box>
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="lg">
        <Footer/>
      </Container>
    </Box>
  )

}

export default ContactComplete
