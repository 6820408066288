// React Imports
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

// MUI Imports
import { createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Actions & GraphQL Imports
import { receiveItem } from "../../../actions/items";
import { addItemToTempCart } from "../../../actions/tempCart";
import { API } from 'aws-amplify';
import { getItemBySku } from '../../../graphql/queries';

const theme = createTheme();

function SubItemDefault({ subItem, items, config }) {

  const { language } = config;
  const [selectedSku, setSelectedSku] = useState(null);
  const dispatch = useDispatch();

  const handleSelectSku = (sku) => {
    setSelectedSku(sku);
    const itemDetail = {
      id: subItem.id,
      labelNames: subItem.names,
      order: subItem.order,
      sku
    };
    dispatch(addItemToTempCart(itemDetail));
  };

  useEffect(() => {
    dispatch(addItemToTempCart({
      id: subItem.id,
      labelNames: subItem.names,
      order: subItem.order,
      sku: null
    }));

    const uniqueSkus = new Set(subItem.skus);

    uniqueSkus.forEach((sku) => {
      API.graphql({
        query: getItemBySku,
        variables: { sku }
      })
      .then((result) => {
        if (result.data.getItemBySku.items.length > 0) {
          dispatch(receiveItem(result.data.getItemBySku.items[0]));
        }
      });
    });
  }, [dispatch, subItem]);

  const getButtonStyle = (isActive) => ({
    color: isActive ? theme.primary : "#C5C5C5",
    borderColor: isActive ? theme.primary : "#C5C5C5"
  });

  return (
    <Box py={2}>
      <Typography pb={2} variant="h6" style={{ color: "rgba(0,0,0,0.87)" }}>
        {subItem.names[language]}
      </Typography>
      {subItem.descriptions[language] && (
        <Box pb={2}>
          <Typography pb={1} style={{ color: "rgba(0,0,0,0.4)" }} variant="body2">
            {subItem.descriptions[language]}
          </Typography>
        </Box>
      )}
      {subItem.skus && (
        <Box pb={2}>
          <Grid container spacing={2}>
            <Grid item key="none">
              <Button
                onClick={() => handleSelectSku(null)}
                variant="outlined"
                style={getButtonStyle(selectedSku === null)}
              >
                なし
              </Button>
            </Grid>
            {subItem.skus.map((sku) => (
              <Grid item key={sku}>
                <Button
                  onClick={() => handleSelectSku(sku)}
                  variant="outlined"
                  style={getButtonStyle(selectedSku === sku)}
                  disabled={!items[sku]}
                >
                  {items[sku]?.shortNames[language] || sku}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { items } = state;
  const skusFromProps = ownProps.subItem.skus;
  const filteredItems = Object.keys(items)
    .filter(sku => skusFromProps.includes(sku))
    .reduce((acc, sku) => ({ ...acc, [sku]: items[sku] }), {});

  return {
    items: filteredItems,
    config: state.config
  };
};

export default connect(mapStateToProps)(SubItemDefault);
