import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";


const language = "ja";


function TempCartSummary(props) {
  const { items, tempCart } = props
  const itemsInTempCart = tempCart.items
  return(
    <Box>
      { Object.keys(itemsInTempCart).map((key) => {
        const itemInTempCart = itemsInTempCart[key]
        const labelName = itemInTempCart.labelNames[language]
        const sku = itemInTempCart.sku
        const price = sku ? `${items[sku].price.toLocaleString("ja-JP")}` : ""
        const skuUpperCase = sku ? sku.toUpperCase() : "なし"
        return (
          <Fragment key={key}>
            <Typography variant="body2">
              {labelName}: {skuUpperCase} {sku && ('(')}
              <span style={{ fontFamily: 'DIN Alternate' }}>{sku && (price)}</span>
              <span style={{ fontSize: '0.6rem' }}>{sku && ('円')}</span>
              {sku && (')')}
            </Typography>
          </Fragment>
        )
      }) }
    </Box>
  )
}


function mapStateToProps({ items, tempCart }) {
  let itemSkusInTempCart = []
  Object.keys(tempCart.items).forEach(id => {
    if (tempCart.items[id].sku !== null) {
      itemSkusInTempCart.push(tempCart.items[id].sku)
    }
  })
  const filteredItems = itemSkusInTempCart.reduce((obj, sku) => {
                          if (items.hasOwnProperty(sku)) {
                            obj[sku] = items[sku];
                          }
                          return obj;
                        }, {});
  return {
    items: filteredItems,
    tempCart: tempCart
  };
}


export default connect(mapStateToProps)(TempCartSummary);
