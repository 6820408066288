import {
  ADD_ITEM_TO_TEMP_CART,
  EMPTY_TEMP_CART,
  UPDATE_QUANTITY_IN_TEMP_CART
} from "../actions/tempCart";


const initialState = {
  quantity: "",
  items: {}
}

export default function cart(state = initialState, action) {
  switch (action.type) {
    case ADD_ITEM_TO_TEMP_CART:
      return {
        ...state,
        items: {
          ...state.items,
          [action.item.id]: action.item
        }
      };
    case EMPTY_TEMP_CART:
      return initialState
    case UPDATE_QUANTITY_IN_TEMP_CART:
      return {
        ...state,
        quantity: action.quantity
      };
    default:
      return state;
  }
}
