import { createTheme } from '@mui/material/styles';

const landing = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#5DAB47',
          fontFamily: '\'YuGothic\', \'游ゴシック\', \'Hiragino Kaku Gothic ProN\', \'Meiryo\', \'メイリオ\', \'ＭＳ Ｐゴシック\', \'sans-serif\'',
          padding: 0
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  typography: {
    fontFamily: '\'YuGothic\', \'游ゴシック\', \'Hiragino Kaku Gothic ProN\', \'Meiryo\', \'メイリオ\', \'ＭＳ Ｐゴシック\', \'sans-serif\'', // Set the global font family
  },
  // Add custom styles
  customStyles: {
    logoBox: {
      display: { xs: 'none', sm: 'block' },
      '& img': {
        height: '64px',
        marginRight: '16px',
      },
    },
  },
});

export default landing;
