import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCartOutlined";

import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";

const links = {
  0: {
    name: {
      en: "Shopping Cart",
      ja: "カート"
    }
  }
};


function EmptyCart() {
  return (
    <Box>
      <Container maxWidth="lg">
        <Header />
      </Container>
      <CssBaseline />
      <Navigation links={links} />
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <ShoppingCartIcon
            sx={{ fontSize: 150 }}
            style={{ color: "rgb(237, 241, 247)" }}
          />
          <Typography style={{ color: "rgb(30, 32, 34)" }} py={2}>
            カートに商品が入ってません。
          </Typography>
          <Typography style={{ color: "rgb(103, 119, 136)" }}>
            現在、買い物かごには商品が入っていません。ぜひお買い物をお楽しみください。商品一覧のページよりご確認ください。
          </Typography>
          <Box py={4}>
            <Button
              component={Link}
              to={`/`}
              variant="contained"
              disableElevation
            >
              商品一覧
            </Button>
          </Box>
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="lg">
        <Footer/>
      </Container>
    </Box>
  );
}

export default EmptyCart;
