import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';


function CartItemSkeleton() {
  return(
    <Card sx={{ backgroundColor: "#f5f5f5", boxShadow: "none", display: "flex", width: "100%"}}>
      <CardActionArea sx={{maxWidth: 300, p:2}}>
        <Skeleton animation="wave" variant="rectangular" height={150} style={{width: "100%"}}/>
      </CardActionArea>
      <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
        <CardContent sx={{py:2}}>
          <Typography component="div" variant={"h6"} sx={{mb: 2}}>
            <Skeleton animation="wave" variant="rectangle"/>
          </Typography>
          <Skeleton animation="wave" variant="rectangle" sx={{mb: 1}}/>
          <Skeleton animation="wave" variant="rectangle"/>
        </CardContent>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign:"right"
        }}
      >
        <Typography component="div" variant={"h6"} sx={{p:2}}>
          <Skeleton animation="wave" variant="rectangle"/>
        </Typography>
      </Box>
    </Card>
  )
}


export default CartItemSkeleton;