import React from 'react';

import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid"
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import { removeSearchResultItems } from "../../actions/search"

function SearchDialog(props) {

  const { onClose, open } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { control, handleSubmit } = useForm({
    defaultValues: {
      keyword: ''
    }
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (formData, e) => {
    onClose();
    dispatch(removeSearchResultItems())
    navigate(`/search?page=1&keyword=${formData.keyword}`);
  }
  const onError = (errors, e) => {
    console.log(errors)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={handleClose}
      open={open}
      scroll="paper"
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start"
        }
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent>
          <Grid item xs={12} pt={4} pb={2}>
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => <TextField
                autoFocus
                fullWidth
                id="keyword"
                label="キーワード"
                size="small"
                variant="standard"
                {...field} />}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px" }}>
          <Button disableElevation onClick={handleSubmit(onSubmit, onError)} type="submit" variant="contained">
            検索
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default SearchDialog;