import { Component } from "react";
import { connect } from "react-redux";

import { API } from 'aws-amplify'
import { getItemBySku } from '../../graphql/queries'

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";
import ItemSkeleton from "./ItemSkeleton";
import ItemImage from "./ItemImage";
import ScrollToTop from "../ScrollToTop";
import SubItemDefault from "./SubItem/SubItemDefault";
import SubItemGroups from "./SubItem/SubItemGroups";
import SubItemTypes from "./SubItem/SubItemTypes";
import TempCartQuantity from "../TempCart/TempCartQuantity";
import TempCartSummary from "../TempCart/TempCartSummary";
import TempCartToCartButton from "../TempCart/TempCartToCartButton";
import TempCartTotalPrice from "../TempCart/TempCartTotalPrice";

import { addItems } from "../../actions/cart";
import { receiveItem } from "../../actions/items";
import {
  addItemToTempCart,
  emptyTempCart } from "../../actions/tempCart"


class Item extends Component {
  state = {
    loading: {
      item: true
    },
    items: {},
    licenseTypes: [],
    buy: {
      item: this.props.itemSku,
      quantity: 1
    },
    selected: {}
  };

  addToCart = (e) => {
    e.preventDefault();

    let items = []

    Object.keys(this.state.buy).forEach((key) => {
      if (key !== "quantity") {
        const itemId = this.state.buy[key]
        items.push({
          id: itemId,
          quantity: this.state.buy.quantity,
          price: this.state.items[itemId].price
        })
      }
    })
    const { dispatch } = this.props;

    dispatch(addItems(items));
  };

  selectSubItem = (subItem, sku, e) => {
    this.setState((previousState) => ({
      ...previousState,
      buy: {
        ...previousState.buy,
        [subItem]: sku
      }
    }));
  };

  async componentDidMount(prevProps, prevState) {

    let item = {};
    const { itemSku, dispatch } = this.props;

    // Empty TempCart first so previous items don't exist in TempCart
    dispatch(emptyTempCart())

    // Load item details
    try {
      const result = await API.graphql({
        query: getItemBySku,
        variables: {
          sku: itemSku,
        },
      })

      if (result.data) {
        item = result.data.getItemBySku.items[0]
        dispatch(receiveItem(item))
        dispatch(addItemToTempCart({
          id: "main",
          labelNames:  {
            "en": "Main",
            "ja": "本体"
          },
          order: 0,
          sku: itemSku
        }))
      }

      this.setState((previousState) => ({
        ...previousState,
        buy: {
          ...previousState.buy
        },
        items: {
          ...previousState.items,
          [item.sku]: item
        },
        loading: {
          ...previousState.loading,
          item: false
        },
        totalPrice: item.price
      }));
    } catch (error) {
      console.error(error);
      this.setState((previousState) => ({
        ...previousState,
        loading: {
          ...previousState.loading,
          item: false
        }
      }));
    }
  }

  render() {
    const { itemSku, theme, config } = this.props;
    const language = config.language
    const loading = this.state.loading;
    const items = this.state.items;
    const links = {
      0: {
        name: {
          en: "Product List",
          ja: "商品一覧"
        },
        type: "react-router-dom",
        action: "back"
      },
      1: {
        name: {
          en: items[itemSku] ? items[itemSku].sku.toUpperCase() : null,
          ja: items[itemSku] ? items[itemSku].sku.toUpperCase() : null
        },
        type: "text"
      }
    };

    if (loading.item) {
      return (
        <Box>
          <ScrollToTop />
          <Container maxWidth="lg">
            <Header />
          </Container>
          <CssBaseline />
          <Navigation links={links} />
          <ItemSkeleton theme={theme}/>
          <Container maxWidth="lg">
            <Footer/>
          </Container>
        </Box>
      );
    } else {
      const item = items[itemSku]
      const skuUpperCase = item.sku.toUpperCase()
      const name = item.names[language]
      const imageUrl = item.images.frontShadow ? item.images.frontShadow : 'https://openkanal.s3.ap-northeast-1.amazonaws.com/public/common/no-image.png'
      const imageDescription = item.images.frontShadow ? `${skuUpperCase} with front shadow` : 'No image'

      return (
        <Box>
          <ScrollToTop />
          <Container maxWidth="lg">
            <Header />
          </Container>
          <CssBaseline />
          <Navigation links={links} />
          <Container maxWidth="lg">
            <Grid container spacing={2} py={6}>
              <Grid item xs={12} md={6} sx={{
                  "& .markdown": {
                    py: 3
                  }
                }}
              >
                <Box sx={{ width: "100%", backgroundColor: "white"}}>
                  <ItemImage alt={imageDescription} src={imageUrl} width={986} height={240}/>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  "& .markdown": {
                    py: 3
                  }
                }}
              >
                <Typography
                  variant="h4"
                  pb={2}
                  style={{ color: "rgba(0,0,0,0.87)" }}
                >
                  {name}
                </Typography>
                <Grid container direction="row" py={2} alignItems="center">
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TempCartTotalPrice />
                  </Grid>
                  <Grid item lg={3} md={3} sm={2} xs={6} px={2}
                    sx={{
                      [theme.breakpoints.only("xs")]: {
                        px: 0,
                        pr: 2,
                        py: 2
                      }
                    }}
                  >
                    <TempCartQuantity />
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    md={5}
                    sm={6}
                    xs={6}
                    style={{ height: "100%" }}
                  >
                    <TempCartToCartButton />
                  </Grid>
                </Grid>
                <Box py={2} style={{ color: "rgba(0,0,0,0.6)" }}>
                  <Typography pb={2}>
                    上記金額(税込)には下記が含まれています。
                  </Typography>
                  <TempCartSummary />
                </Box>
                <Box py={2}>
                  <Divider></Divider>
                </Box>
                {items[itemSku].subItems.map((subItem) => {
                  if (subItem.displayType === "group") {
                    return (<SubItemGroups key={subItem.id} subItem={subItem} />)
                  }
                  else if (subItem.displayType === "type") {
                    return (<SubItemTypes key={subItem.id} subItem={subItem} />)
                  }
                  else {
                    return (<SubItemDefault key={subItem.id} subItem={subItem} />)
                  }
                })}
              </Grid>
            </Grid>
          </Container>
          <Divider />
          <Container maxWidth="lg">
            <Footer/>
          </Container>
        </Box>
      );
    }
  }
}

function mapStateToProps({ cart, config }) {
  return {
    cart: cart,
    config: config
  };
}

export default connect(mapStateToProps)(Item);
