import { connect, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";

import { updateQuantityInTempCart } from "../../actions/tempCart";


function TempCartQuantity(props) {

  const dispatch = useDispatch()

  function changeQuantity(e) {
    const rx = /^[1-9]\d*$/
    if (rx.test(e.target.value)) {
      dispatch(updateQuantityInTempCart(parseInt(e.target.value)))
    }
    else {
      dispatch(updateQuantityInTempCart(e.target.value))
    }
  };

  const quantity = props.quantity

  return(
    <Box>
      <FormControl
        sx={{ width: "100%" }}
        variant="outlined"
        size="small"
      >
        <OutlinedInput
          id="tempCartTotalQuantity"
          onChange={(e) => changeQuantity(e)}
          placeholder="個数"
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight"
          }}
          value={quantity}
        />
      </FormControl>
    </Box>
  )
}


function mapStateToProps({ tempCart }) {
  return {
    quantity: tempCart.quantity
  };
}


export default connect(mapStateToProps)(TempCartQuantity);
