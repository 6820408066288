import { useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";

import { API } from 'aws-amplify'

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField";

import ContactComplete from "./ContactComplete"
import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";


export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      createdAt
    }
  }
`;


function ContactForm() {

  const links = {
    0: {
      name: {
        en: "Contact",
        ja: "お問い合わせ"
      }
    }
  };

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      firstName: '',
      select: {}
    }
  });
  const requiredKeys = ["firstName", "lastName", "lastNamePhonetic", "firstNamePhonetic",
                        "email", "phone", "message"];
  const defaultWatchedValues = watch(requiredKeys);
  useEffect(() => {
    const isFormValid = defaultWatchedValues.filter((value) => typeof value !== "boolean").every((value) =>
      {
        return value && value !== ""
      });
    setIsButtonDisabled(!isFormValid);
  }, [defaultWatchedValues]);

  const [complete, setComplete] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [refID, setRefID] = useState(null)
  const [errors, setErrors] = useState({})
  const onSubmit = async (formData, e) => {
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      lastNamePhonetic: formData.lastNamePhonetic,
      firstNamePhonetic: formData.firstNamePhonetic,
      email: formData.email,
      phone: formData.phone[0] === 0 ? formData.phone.slice(1) : '+81' + formData.phone,
      message: formData.message
    }
    try {
      const result = await API.graphql({
        query: createMessage,
        variables: {
          input: data,
        },
      })
      console.log(result)
      setRefID(`M-${Date.parse(result.data.createMessage.createdAt)}`)
      setComplete(true)
    } catch (err) {
      console.error(err)
    }
  }
  const onError = (errors, e) => {
    console.log(errors)
    setErrors(errors)
  }


  if (complete) {
    return(
      <ContactComplete refID={refID}/>
    )
  }
  else {
    return (
      <Box>
        <Container maxWidth="lg">
          <Header />
        </Container>
        <CssBaseline />
        <Navigation links={links} />
        <Container maxWidth="lg" sx={{py:6}}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container spacing={4}>
              <Grid item xs={12} pb={1}>
                お名前
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingTop: 0}} pb={1}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <TextField
                    autoComplete="family-name"
                    error={errors.hasOwnProperty("lastName")}
                    fullWidth
                    // helperText={errors.hasOwnProperty("lastName")}
                    id="lastName"
                    label="姓"
                    required
                    size="small"
                    variant="outlined"
                    {...field} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingTop: 0}} pb={2}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <TextField
                    autoComplete="given-name"
                    error={errors.hasOwnProperty("firstName")}
                    fullWidth
                    id="firstName"
                    label="名"
                    required
                    size="small"
                    variant="outlined"
                    {...field} />}
                />
              </Grid>
              <Grid item xs={12} pb={1} style={{ paddingTop: 0 }} >
                ふりがな
              </Grid>
              <Grid item xs={12} sm={6} pb={1} style={{ paddingTop: 0}}>
                <Controller
                  name="lastNamePhonetic"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <TextField
                    error={errors.hasOwnProperty("lastNamePhonetic")}
                    fullWidth
                    id="lastNamePhonetic"
                    label="せい"
                    required
                    size="small"
                    variant="outlined"
                    {...field} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} pb={1} style={{ paddingTop: 0}}>
                <Controller
                  name="firstNamePhonetic"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>  <TextField
                    error={errors.hasOwnProperty("firstNamePhonetic")}
                    fullWidth
                    id="firstNamePhonetic"
                    label="めい"
                    required
                    size="small"
                    variant="outlined"
                    {...field} />}
                />
              </Grid>
              <Grid item xs={12} pb={1}>
                連絡先
              </Grid>
              <Grid item xs={12} style={{paddingTop:0}} pb={2}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                  render={({ field }) => <TextField
                    autoComplete="email"
                    error={errors.hasOwnProperty("email")}
                    fullWidth
                    id="email"
                    label="メールアドレス"
                    required
                    size="small"
                    variant="outlined"
                    {...field} />}
                  />
              </Grid>
              <Grid item xs={12} style={{paddingTop:0}}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true, pattern: /^\d{2,4}-\d{2,4}-\d{4}$|^(0{1}\d{9,10})$|^0[5789]0-[0-9]{4}-[0-9]{4}$|^(050|070|080|090)-\d{4}-\d{4}$/i }}
                  render={({ field }) => <TextField
                    autoComplete="tel"
                    error={errors.hasOwnProperty("tel")}
                    fullWidth
                    helperText="半角で入力してください"
                    id="phone"
                    label="電話番号"
                    required
                    size="small"
                    variant="outlined"
                    {...field} />}
                  />
              </Grid>
              <Grid item xs={12} pt={6}>
                <Box xs={12} pb={2}>お問い合わせ内容</Box>
                <Controller
                  name="message"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>  <TextField
                    error={errors.hasOwnProperty("message")}
                    fullWidth
                    id="message"
                    label="1000文字以内"
                    multiline
                    required
                    rows={8}
                    size="small"
                    variant="outlined"
                    {...field} />}
                />
              </Grid>
              <Grid item xs={12} pt={6}>
                <Button
                  disabled={isButtonDisabled}
                  onClick={handleSubmit(onSubmit, onError)}
                  variant="contained"
                  disableElevation
                  size="large"
                  style={{width: "100%"}}
                >
                  送信
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
        <Divider />
        <Container maxWidth="lg">
          <Footer/>
        </Container>
      </Box>
    );
  }
}

export default ContactForm;
