import { useState } from "react";

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';


const ItemImage = (props) => {

  const { alt, src, width, height } = props;
  const [ loaded, setLoaded ] = useState(false)

  const handleOnLoad = () => {
    setLoaded(true)
  }

  return (
    <Box>
      {!loaded && (<Skeleton variant="rectangular" width={width} height={height} style={{ width: "100%" }}>
        <img alt={alt} src={src} style={{ width: "100%" }} onLoad={handleOnLoad}/>
      </Skeleton>)}
      {loaded && (<img alt={alt} src={src} style={{ width: "100%" }}/>)}
    </Box>
  )
}

export default ItemImage