import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Skeleton from '@mui/material/Skeleton';
import Typography from "@mui/material/Typography";


function ItemCardVerticalSkeleton() {

  return (
    <Card>
      <Skeleton variant="rectangular" height={170}/>
      <CardContent>
        <Typography variant="body2" color="text.secondary" pb={2}>
          <Skeleton variant="rectangle"/>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <Skeleton variant="rectangle"/>
        </Typography>
      </CardContent>
      <CardActions sx={{ px: 2 }}>
        <Skeleton variant="rectangular" height={30} style={{width: '100%'}}/>
      </CardActions>
    </Card>
  );
}

export default ItemCardVerticalSkeleton;
