import { connect } from "react-redux";

import Box from "@mui/material/Box";

import CartItemCardVertical from './CartItemCardVertical';
import CartItemSkeleton from "./CartItemSkeleton";


function CartItemList(props) {

  const { cart, items } = props;
  const idsInCart = Object.keys(cart);

  return (
    <Box>
      {idsInCart.map((id) => {
        const cartItem = cart[id];
        const itemSku = cart[id].sku;
        const item = items[itemSku];
        if (item) {
          return (
            <Box key={id} sx={{alignItems: "center", display: "flex", flexDirection: "column", pb: 2}}>
              <CartItemCardVertical cartItemId={id} cartItem={cartItem} item={item}/>
            </Box>
          );
        } else {
          return(
            <Box key={id} sx={{pb:2}}>
              <CartItemSkeleton cartItem={cartItem}></CartItemSkeleton>
            </Box>
          )
        }
      })}
    </Box>
  );
}

function mapStateToProps({ cart, items }) {
  return {
    cart,
    items
  };
}

export default connect(mapStateToProps)(CartItemList);
