import { v4 } from "uuid"


export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const ADD_ITEMS_TO_CART = "ADD_ITEMS_TO_CART";
export const DELETE_ITEM_IN_CART = "DELETE_ITEM_IN_CART";
export const EMPTY_CART = "EMPTY_CART";
export const UPDATE_ITEM_QUANTITY_IN_CART = "UPDATE_ITEM_QUANTITY_IN_CART";


export function addItems(items) {
  return {
    type: ADD_ITEMS_TO_CART,
    items
  };
}

export const addItem = (sku, quantity) => (
  {
    type: ADD_ITEM_TO_CART,
    id: v4(),
    sku,
    quantity
  }
)

export function deleteItemInCart(id) {
  return {
    type: DELETE_ITEM_IN_CART,
    id
  };
}

export function emptyCart() {
  return {
    type: EMPTY_CART
  }
}

export function updateItemQuantity(id, quantity) {
  return {
    type: UPDATE_ITEM_QUANTITY_IN_CART,
    id,
    quantity
  };
}
