import * as React from "react";

import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

function TopNavigation(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      pt={1}
    >
      <Box>
        <Link
          component={RouterLink}
          to="/about"
          variant="body2"
          underline="none"
          color="text.primary"
          pl={2}
        >
          会社情報
        </Link>
        <Link
          component={RouterLink}
          to="/contact"
          variant="body2"
          underline="none"
          color="text.primary"
          pl={2}
        >
          お問い合わせ
        </Link>
      </Box>
    </Grid>
  );
}

export default TopNavigation;
