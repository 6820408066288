import { useState } from "react";
import { connect, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { updateItemQuantity, deleteItemInCart } from "../../actions/cart";


function CartItemCardVertical(props) {
  const {cartItem, cartItemId, config, item } = props
  const [ quantity, setQuantity ] = useState(cartItem.quantity)
  const language = config.language
  const name = item.names[language]
  const price = item.price.toLocaleString("ja-JP")
  const imageUrl = item.images.frontShadow ? item.images.frontShadow: 'https://openkanal.s3.ap-northeast-1.amazonaws.com/public/common/no-image.png'
  const dispatch = useDispatch()

  function changeItemQuantity(e, id) {
    // Filter to only allow positive integers or blank
    const rx = new RegExp(/^[1-9]\d*$/);
    setQuantity(e.target.value)
    if (rx.test(e.target.value)) {
      dispatch(updateItemQuantity(id, parseInt(e.target.value)));
    }
  };

  function deleteItem(e, id) {
    dispatch(deleteItemInCart(id));
  };

  return(
    <Card sx={{ backgroundColor: "#f9f9f9", boxShadow: "none", display: "flex", width: "100%"}}>
      <CardActionArea style={{ maxWidth: 300 }}>
        <CardMedia component="img" image={imageUrl} alt={name}/>
      </CardActionArea>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%"
        }}
      >
        <CardHeader title={name} titleTypographyProps={{variant:'body1' }} sx={{py:2}}/>
        <CardContent sx={{py:1}}>
          <Typography variant="body2" color="text.secondary">
            SKU: {item.sku.toUpperCase()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            納期:
          </Typography>
          {cartItem.memo && (
            <Typography variant="body2" color="text.secondary">
              メモ: {cartItem.memo}
            </Typography>
          )}
        </CardContent>
        <CardContent sx={{py:1}}>
          <TextField
            hiddenLabel
            InputLabelProps={{
              shrink: true
            }}
            inputProps= {{ min: 1 }}
            size="small"
            style={{ width: 50 }}
            type="number"
            value={quantity}
            variant="standard"
            onChange={(e) => changeItemQuantity(e, cartItemId)}
          />
          <Button
            size="small"
            color="primary"
            startIcon={<DeleteOutlineIcon />}
            onClick={(e) => deleteItem(e, cartItemId)}
          >
            削除
          </Button>
        </CardContent>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign:"right"
        }}
      >
        <Typography sx={{p:2}}>
          <span style={{fontFamily: "DIN Alternate", fontSize:"1.1rem"}}>
          {price}
          </span>
          <span style={{fontSize: '0.8rem'}}>
           円
          </span>
        </Typography>
      </Box>
    </Card>
  )
}

function mapStateToProps({ cart, config, items }) {
  return {
    cart,
    config,
    items
  };
}


export default connect(mapStateToProps)(CartItemCardVertical);