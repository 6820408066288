export const ADD_ITEM_TO_TEMP_CART = "ADD_ITEM_TO_TEMP_CART";
export const EMPTY_TEMP_CART = "EMPTY_TEMP_CART";
export const UPDATE_QUANTITY_IN_TEMP_CART = "UPDATE_QUANTITY_IN_TEMP_CART";


export function addItemToTempCart(item) {
  return {
    type: ADD_ITEM_TO_TEMP_CART,
    item
  };
}

export function emptyTempCart() {
  return {
    type: EMPTY_TEMP_CART
  };
}

export function updateQuantityInTempCart(quantity) {
  return {
    type: UPDATE_QUANTITY_IN_TEMP_CART,
    quantity
  };
}
