import {
  RECEIVE_SEARCH_FILTER,
  RECEIVE_SEARCH_KEYWORD,
  RECEIVE_SEARCH_PAGE,
  RECEIVE_SEARCH_RESULT_ITEMS,
  RECEIVE_INITIALIZE_SEARCH,
  REMOVE_SEARCH_RESULT_ITEMS } from "../actions/search";

const initialState = {
  currentToken: null,
  filter: null,
  items: [],
  keyword: null,
  limit: 18,
  nextToken: null,
  page: 1,
  previousTokens: [],
  sort: `[
    {field: searchResultOrder, direction: asc},
    {field: sku, direction: asc}
  ]`,
  total: 0
}

export default function search(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SEARCH_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case RECEIVE_SEARCH_KEYWORD:
      let keyword = null
      action.keyword ? keyword = action.keyword : keyword = null
      return {
        ...state,
        keyword: keyword
      };
      case RECEIVE_SEARCH_PAGE:
        return {
          ...state,
          page: action.page
        };
    case RECEIVE_SEARCH_RESULT_ITEMS:
      return {
        ...state,
        currentToken: action.currentToken,
        items: action.items,
        nextToken: action.nextToken,
        page: action.page,
        previousTokens: action.previousTokens,
        total: action.total
      };
    case RECEIVE_INITIALIZE_SEARCH:
      return initialState
    case REMOVE_SEARCH_RESULT_ITEMS:
      return {
        ...state,
        items: []
      }
    default:
      return state;
  }
}
