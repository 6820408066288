import * as React from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";


const language = "ja";


function Navigation(props) {
  const links = props.links;
  const navigate = useNavigate();

  let breadcrumbs = [];
  Object.keys(links).forEach((key) => {
    if (links[key].type === "url") {
      breadcrumbs.push(
        <Link
          component={RouterLink}
          underline="hover"
          color="inherit"
          to={links[key].url}
          key={key}
        >
          {links[key].name[language]}
        </Link>
      );
    }
    else if (links[key].type === "react-router-dom") {
      breadcrumbs.push(
        <Link color="inherit" component={RouterLink} key={key} onClick={(e) => navigate(-1)} size="large" underline="hover">
          {links[key].name[language]}
        </Link>
      );
    }
    else {
      breadcrumbs.push(
        <Typography color="text.primary" key={key}>
          {links[key].name[language]}
        </Typography>
      );
    }
  });

  return (
    <Box
      sx={{ flexGrow: 1 }}
      py={2.5}
      style={{ backgroundColor: "rgb(247, 250, 255)" }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: {
              sm: "flex"
            },
            justifyContent: "space-between"
          }}
        >
          <Breadcrumbs aria-label="breadcrumb" xs={12}>
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Container>
    </Box>
  );
}

export default Navigation;
