import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";

import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";
import ScrollToTop from "../ScrollToTop";


function About() {

  const links = {
    0: {
      name: {
        en: "About",
        ja: "会社情報"
      }
    }
  };

  return(
    <Box>
      <ScrollToTop />
      <Container maxWidth="lg">
        <Header />
      </Container>
      <CssBaseline />
      <Navigation links={links} />
      <Container maxWidth="lg">
        <Grid container spacing={2} py={6}>
          <Grid
            item
            sx={{
              "& .markdown": {
                py: 3
              }
            }}
          >
            <Typography
              variant="h5"
              pb={4}
              style={{ color: "rgba(0,0,0,0.87)" }}
            >
              会社概要
            </Typography>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell style={{fontSize: "1rem", fontWeight: "bold"}}>会社名</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>OpenKanal合同会社</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontSize: "1rem", fontWeight: "bold"}}>設立</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>2023年1月17日</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontSize: "1rem", fontWeight: "bold"}}>代表取締役</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>小白川 諒</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontSize: "1rem", fontWeight: "bold"}}>所在地</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>〒107-0062 東京都港区南青山3丁目1番36号 青山丸竹ビル6F</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontSize: "1rem", fontWeight: "bold"}}>事業内容</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>クラウドコンピューティング関連サービスの販売</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h5"
              pt={6}
              pb={4}
              style={{ color: "rgba(0,0,0,0.87)" }}
            >
              事業内容
            </Typography>
            <Typography sx={{ lineHeight: 1.8 }} py={1}>
              OpenKanal合同会社はシスコシステムズのクラウド管理型ネットワーク「Meraki」を販売しております。
            </Typography>
            <Typography sx={{ lineHeight: 1.8 }} py={1}>
              Cisco Merakiは、導入のシンプルさ、及びパフォーマンスの高さに定評があります。家庭用との比較はもとより、業務用のアクセスポイントと比較しても、速度の観点からMerakiのものが業界TOPです。
            </Typography>
            <Typography sx={{ lineHeight: 1.8 }} py={1}>
              OpenKanalでは、累計数万台の販売/構築をサポートしてきた経験をもとに、お客様がスピーディーに、簡単に、かつお求めやすい価格でMerakiを購入できるようサポートいたします。
            </Typography>
            <Typography
              variant="h5"
              pt={6}
              pb={4}
              style={{ color: "rgba(0,0,0,0.87)" }}
            >
              社会貢献活動
            </Typography>
            <Typography sx={{ lineHeight: 1.8 }} py={1}>
              1. 地域社会への貢献
            </Typography>
            <List sx={{pl:1}}>
              <ListItemText sx={{py:1}} primary={`・ 地域社会への貢献活動を行い、教育や多様性の推進を支援します。`} />
              <ListItemText sx={{py:1}} primary={`・ ボランティア活動や地域プロジェクトへの参加を奨励します。`} />
            </List>
            <Typography sx={{ lineHeight: 1.8 }} py={1}>
              2. 倫理的ビジネス慣行
            </Typography>
            <List sx={{pl:1}}>
              <ListItemText sx={{py:1}} primary={`・ 法令を遵守し、公正で透明なビジネスを実践します。`} />
              <ListItemText sx={{py:1}} primary={`・ ステークホルダーと誠実な対話を行い、信頼関係を構築します。`} />
            </List>
            <Typography sx={{ lineHeight: 1.8 }} py={1}>
              3. 環境持続可能性
            </Typography>
            <List sx={{pl:1}}>
              <ListItemText sx={{py:1}} primary={`・ 環境に優しい製品の販売を推進し、カーボンフットプリントの削減に努めます。`} />
              <ListItemText sx={{py:1}} primary={`・ エネルギー効率の向上と電車移動の奨励を通じて、持続可能な運営を目指します。`} />
            </List>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container maxWidth="lg">
        <Footer/>
      </Container>
    </Box>
  )
}

export default About;