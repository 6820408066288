import React, { useState } from 'react';

import { ThemeProvider, CssBaseline } from '@mui/material';
import Box from "@mui/material/Box";
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';

import DrawerAppBar from './DrawerAppBar';
import CampaignLandscape from './CampaignLandscape';
import CampaignPortrait from './CampaignPortrait';
import landing from '../../theme/landing';

function Landing() {

  const [currentPage] = useState(1);

  // const handleNextPage = () => {
  //   setCurrentPage(currentPage === 3 ? 1 : currentPage + 1);
  // };

  const isPortrait = useMediaQuery('(orientation: portrait)');

  return (
    <ThemeProvider theme={landing}>
      <CssBaseline />
      <DrawerAppBar />
      <Box height="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {currentPage === 1 ? (
          isPortrait ? (
            <CampaignPortrait />
          ) : (
            <Box height="100vh">
              <Hidden mdUp>
                <CampaignPortrait />
              </Hidden>
              <Hidden only={['xs', 'sm']}>
                <CampaignLandscape />
              </Hidden>
            </Box>
          )
        ) : null}
        {currentPage === 2 && <CampaignPortrait />}
        {currentPage === 3 && <CampaignLandscape />}
        {/* <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleNextPage}>
            Next Page
          </Button>
        </Box> */}
      </Box>
    </ThemeProvider>
  );
}

export default Landing;
