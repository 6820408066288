import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function ItemSkeleton(props) {

  const { theme } = props

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} py={6}>
        <Grid id="itemImage" item xs={12} md={6} sx={{"& .markdown": {py: 3}}}>
          <Skeleton variant="rectangular" width={986} height={240} style={{ width: "100%" }}/>
        </Grid>
        <Grid item xs={12} md={6} sx={{"& .markdown": {py: 3}}}>
          <Typography id="itemName" variant="h4" pb={2}>
            <Skeleton variant="rectangle"/>
          </Typography>
          <Grid container direction="row" py={2} alignItems="center">
            <Grid id="itemPrice" item sm={4} xs={12}>
              <Typography variant="h5">
                <Skeleton variant="rectangle" />
              </Typography>
            </Grid>
            <Grid id="itemQuantity" item md={3} sm={2} xs={6} px={2} sx={{[theme.breakpoints.only("xs")]: {px: 0,pr: 2,py: 2}}}>
              <FormControl variant="outlined" size="small">
                <OutlinedInput placeholder="個数" disabled/>
              </FormControl>
            </Grid>
            <Grid id="addToCart" item md={5} xs={6}>
              <Button variant="contained" style={{ width: "100%" }} disabled>カートに追加</Button>
            </Grid>
          </Grid>
          <Box id="tempCartSummary" py={2}>
            <Skeleton variant="rectangle" pb={2} height={120}/>
          </Box>
          <Box py={2}>
            <Divider></Divider>
          </Box>
          <Skeleton id="subItems" variant="rectangle" pb={2} height={120}/>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ItemSkeleton;
