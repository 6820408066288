import { connect } from "react-redux";

import Typography from "@mui/material/Typography";

import CartTotalPriceSkeleton from "./CartTotalPriceSkeleton";


function CartTotalPrice(props) {

  const { cart, items, } = props;
  let loading = false
  const uniqueSkus = new Set()
  const idsInCart = Object.keys(cart)
  idsInCart.forEach((id) => {
    uniqueSkus.add(cart[id].sku)
  })

  let totalPrice = 0
  idsInCart.forEach((id) => {
    const sku = cart[id].sku
    const quantity = cart[id].quantity
    if (items[sku]) {
      totalPrice += items[sku].price * quantity
    } else {
      loading = true
    }
  })

  return (
    loading ? (
      <CartTotalPriceSkeleton />
    ) : (
      <Typography>
          <span>小計(税込): </span>
          <span style={{fontFamily: "DIN Alternate", fontSize: "1.3rem"}}>
          {totalPrice.toLocaleString("ja-JP")}
          </span>
          <span style={{fontSize: '1rem'}}>
           円
          </span>
      </Typography>
    )
  )
}

function mapStateToProps({ cart, config, items }) {
  return {
    cart: cart,
    config: config,
    items: items
  };
}

export default connect(mapStateToProps)(CartTotalPrice)
