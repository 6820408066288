import { useParams } from "react-router-dom";

import Item from "./Item";

function ItemWrapper({ theme }) {
  const { itemSku } = useParams();

  return <Item itemSku={itemSku} theme={theme} />;
}

export default ItemWrapper;
