import React, {Component} from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";

import TopNavigation from "./TopNavigation";
import CartButton from "./Cart/CartButton";


class Header extends Component {

  render() {

    return (
      <Box sx={{ flexGrow: 1 }}>
        <TopNavigation />
        <AppBar position="static" elevation={0} color="transparent">
          <Toolbar
            sx={{ justifyContent: "space-between" }}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            color="transparent"
          >
            <Link
              color="text.primary"
              component={RouterLink}
              sx={{ flexGrow: 1 }}
              to="/"
              underline="none"
              variant="h6"
            >
              OpenKanal
            </Link>
            <Link
              component={RouterLink}
              to="/search"
              underline="none"
              color="text.primary"
              pl={4}
            >
              商品一覧
            </Link>
            <Box pl={4}>
              <CartButton />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    )
  };
}


function mapStateToProps({ cart }) {
  return {
    cart
  };
}

export default connect(mapStateToProps)(Header)
