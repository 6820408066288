import { connect } from "react-redux";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";


function CartButton(props) {
  const { quantity } = props

  return(
    <Button
      component={Link}
      to={`/cart`}
      variant="contained"
      disableElevation
    >
      <Badge badgeContent={quantity} color="secondary">
        <ShoppingCartIcon sx={{ color: "white" }} />
      </Badge>
    </Button>
  )
}

function mapStateToProps({ cart }) {
  let quantity = 0
  Object.keys(cart).forEach((key) => {
    quantity += cart[key].quantity
  })
  return {
    quantity: quantity
  };
}
  

export default connect(mapStateToProps)(CartButton);