import { useMemo, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { API } from 'aws-amplify'
import { getItemBySku } from '../../graphql/queries'

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";

import CartItemList from "./CartItemList";
import CartTotalPrice from "./CartTotalPrice";
import EmptyCart from "./EmptyCart";
import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";

import { receiveItem } from "../../actions/items";


function Cart(props) {

  const { cart, items } = props
  const dispatch = useDispatch()
  const cachedItems = Object.keys(items)

  // Use useMemo to initialize uniqueSkus
  const uniqueSkus = useMemo(() => {
    const skus = new Set();
    const idsInCart = Object.keys(cart);
    idsInCart.forEach((id) => {
      skus.add(cart[id].sku);
    });
    return skus;
  }, [cart]);

  const links = {
    0: {
      name: {
        en: "Shopping Cart",
        ja: "カート"
      }
    }
  };

  useEffect(() => {
    const fetchItem = async (itemSku) => {
      const result = await API.graphql({
        query: getItemBySku,
        variables: {
          sku: itemSku,
        },
      })
      const item = result.data.getItemBySku.items[0]
      dispatch(receiveItem(item))
    }
    uniqueSkus.forEach((sku) => {
      if (!cachedItems.includes(sku)) {
        fetchItem(sku)
          .catch(console.error);
      }
    })
  }, [dispatch, cachedItems, uniqueSkus])

  return (
    Object.keys(cart).length > 0 ?
      <Box>
        <Container maxWidth="lg">
          <Header />
        </Container>
        <CssBaseline />
        <Navigation links={links} />
        <Container maxWidth="lg">
          <Grid container spacing={4} py={2}>
            <Grid item xs={12} sm={12} md={8}
              sx={{
                "& .markdown": {
                  py: 3
                }
              }}>
              <CartItemList/>
            </Grid>
            <Grid item xs={12} sm={12} md={4}
              sx={{
                "& .markdown": {
                  py: 3
                }
              }}>
              <Card sx={{ display: "flex", width: "100%", boxShadow: "none", backgroundColor: "rgb(247, 250, 255)"}}>
                <CardContent sx={{width:"100%"}}>
                  <Box sx={{fontWeight: 'bold'}} xs={12} pb={4}>
                    見積もり概要
                  </Box>
                  <CartTotalPrice />
                  <Box pt={4}>
                    <Button
                      component={Link}
                      to={`/checkout`}
                      variant="contained"
                      disableElevation
                      size="large"
                      style={{width: "100%"}}
                    >
                      手続きに進む
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Container maxWidth="lg">
          <Footer/>
        </Container>
      </Box> : <EmptyCart/>)
}

function mapStateToProps({ cart, items }) {
  return {
    cart,
    items
  };
}


export default connect(mapStateToProps)(Cart);
