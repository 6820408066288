import React, { useState, useMemo, useEffect, Fragment } from "react";
import { connect, useDispatch } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import { API } from 'aws-amplify'
import { getItemBySku } from '../../graphql/queries'

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import CartTotalPrice from "../Cart/CartTotalPrice";
import CheckoutComplete from "./CheckoutComplete"
import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";

import { emptyCart } from "../../actions/cart";
import { receiveItem } from "../../actions/items";


const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      createdAt
    }
  }
`;

function CheckoutForm(props) {

  const links = {
    0: {
      name: {
        en: "Shopping Cart",
        ja: "見積もり取得手続"
      }
    }
  };
  const prefectures = require("../../utils/todofuken.json")

  const { cart, config, items } = props
  const language = config.language

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      lastName: '',
      firstName: '',
      lastNamePhonetic: '',
      firstNamePhonetic: '',
      organization: '',
      department: '',
      billingBuilding: '',
      billingCity: '',
      billingPostalCode: '',
      billingPrefecture: '',
      billingStreet: '',
      sameAsBillingAddress: false,
      shippingBuilding: '',
      shippingCity: '',
      shippingPostalCode: '',
      shippingPrefecture: undefined,
      shippingStreet: '',
      email: '',
      phone: '',
      select: {}
    }
  });
  const [complete, setComplete] = useState(false)
  const [refID, setRefID] = useState(null)
  const [errors, setErrors] = useState({})
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const formKeyValueMapping = {
    'lastName': "お名前 - 姓",
    'firstName': "お名前 - 名",
    'lastNamePhonetic': "ふりがな - せい",
    'firstNamePhonetic': "ふりがな - めい",
    'billingPostalCode': "請求先住所 - 郵便番号",
    'billingPrefecture': "請求先住所 - 都道府県",
    'billingCity': "請求先住所 - 市区町村",
    'billingStreet': "請求先住所 - 町名以下",
    'shippingPostalCode': "届け先住所 - 郵便番号",
    'shippingPrefecture': "届け先住所 - 都道府県",
    'shippingCity': "届け先住所 - 市区町村",
    'shippingStreet': "届け先住所 - 町名以下",
    'email': "連絡先 - メールアドレス",
    'phone': "連絡先 - 電話番号",
  }
  const requiredKeys = ["firstName", "lastName", "lastNamePhonetic", "firstNamePhonetic",
                        "billingPostalCode", "billingPrefecture", "billingCity", "billingStreet",
                        "sameAsBillingAddress", "shippingPostalCode", "shippingPrefecture", "shippingCity",
                        "shippingStreet", "email", "phone"];
  const defaultWatchedValues = watch(requiredKeys);
  const sameAsBillingWatchedValues = watch(requiredKeys.filter(value => !value.startsWith("shipping")));
  const sameAsBillingAddress = watch("sameAsBillingAddress");


  const onSubmit = async (formData, e) => {
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      lastNamePhonetic: formData.lastNamePhonetic,
      firstNamePhonetic: formData.firstNamePhonetic,
      organization: formData.organization,
      department: formData.department,
      billingPostalCode: formData.billingPostalCode,
      billingPrefecture: formData.billingPrefecture,
      billingCity: formData.billingCity,
      billingStreet: formData.billingStreet,
      billingBuilding: formData.billingBuilding,
      sameAsBillingAddress: formData.sameAsBillingAddress,
      shippingPostalCode: formData.sameAsBillingAddress ? null : formData.shippingPostalCode,
      shippingPrefecture: formData.sameAsBillingAddress ? null : formData.shippingPrefecture,
      shippingCity: formData.sameAsBillingAddress ? null : formData.shippingCity,
      shippingStreet: formData.sameAsBillingAddress ? null : formData.shippingStreet,
      shippingBuilding: formData.sameAsBillingAddress ? null : formData.shippingBuilding,
      email: formData.email,
      phone: formData.phone[0] === 0 ? formData.phone.slice(1) : '+81' + formData.phone,
      cart: Object.keys(cart).map(key => ({
        'sku': cart[key].sku,
        'name': items[cart[key].sku].names[language],
        'quantity': cart[key].quantity,
        'price': items[cart[key].sku].price
      }))
    }
    try {
      const result = await API.graphql({
        query: createOrder,
        variables: {
          input: data,
        },
      })
      setRefID(`O-${Date.parse(result.data.createOrder.createdAt)}`)
      dispatch(emptyCart())
      setComplete(true)
    } catch (err) {
      console.error(err)
    }
  }
  const onError = (errors, e) => {
    setErrors(errors)
  }

  // Use useMemo to initialize uniqueSkus
  const uniqueSkus = useMemo(() => {
    const skus = new Set();
    const idsInCart = Object.keys(cart);
    idsInCart.forEach((id) => {
      skus.add(cart[id].sku);
    });
    return skus;
  }, [cart]);

  const dispatch = useDispatch()
  const cachedItems = Object.keys(items)

  useEffect(() => {
    const fetchItem = async (itemSku) => {
      const result = await API.graphql({
        query: getItemBySku,
        variables: {
          sku: itemSku,
        },
      })
      const item = result.data.getItemBySku.items[0]
      dispatch(receiveItem(item))
    }
    uniqueSkus.forEach((sku) => {
      if (!cachedItems.includes(sku)) {
        fetchItem(sku)
          .catch(console.error);
      }
    })
  }, [dispatch, cachedItems, uniqueSkus])

  useEffect(() => {
    const isFormValid = sameAsBillingAddress
      ? sameAsBillingWatchedValues.every((value) =>
        {
          return value && value !== ""
        })
      : defaultWatchedValues.filter((value) => typeof value !== "boolean").every((value) =>
        {
          return value && value !== ""
        });
    setIsButtonDisabled(!isFormValid);
  }, [defaultWatchedValues, sameAsBillingAddress, sameAsBillingWatchedValues]);

  if (complete) {
    return(
      <CheckoutComplete refID={refID}/>
    )
  }
  else {
    return(
      <Box>
        <Container maxWidth="lg">
          <Header />
        </Container>
        <CssBaseline />
        <Navigation links={links} />
        <Container maxWidth="lg">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container spacing={4} py={2}>
              <Grid
                item
                sm={12}
                md={8}
                sx={{
                  "& .markdown": {
                    py: 4
                  }
                }}
              >
                {Object.keys(errors).length > 0 && (
                  <Box pb={4}>
                    <Alert severity="error">
                      <AlertTitle>入力内容に誤りがあります。下記を修正し、再度送信してください。</AlertTitle>
                      <ul>
                        {Object.keys(errors).map((formKey) => (
                          <li key={formKey}>{formKeyValueMapping[formKey]}</li>
                        ))}
                      </ul>
                    </Alert>
                  </Box>
                )}
                <Grid container spacing={4}>
                  <Grid item xs={12} pb={1}>
                    お名前
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0}} pb={1}>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <TextField
                        autoComplete="family-name"
                        error={errors.hasOwnProperty("lastName")}
                        fullWidth
                        // helperText={errors.hasOwnProperty("lastName")}
                        id="lastName"
                        label="姓"
                        required
                        size="small"
                        variant="outlined"
                        {...field} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0}} pb={2}>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <TextField
                        autoComplete="given-name"
                        error={errors.hasOwnProperty("firstName")}
                        fullWidth
                        id="firstName"
                        label="名"
                        required
                        size="small"
                        variant="outlined"
                        {...field} />}
                    />
                  </Grid>
                  <Grid item xs={12} pb={1} style={{ paddingTop: 0 }} >
                    ふりがな
                  </Grid>
                  <Grid item xs={12} sm={6} pb={1} style={{ paddingTop: 0}}>
                    <Controller
                      name="lastNamePhonetic"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <TextField
                        error={errors.hasOwnProperty("lastNamePhonetic")}
                        fullWidth
                        id="lastNamePhonetic"
                        label="せい"
                        required
                        size="small"
                        variant="outlined"
                        {...field} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} pb={1} style={{ paddingTop: 0}}>
                    <Controller
                      name="firstNamePhonetic"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>  <TextField
                        error={errors.hasOwnProperty("firstNamePhonetic")}
                        fullWidth
                        id="firstNamePhonetic"
                        label="めい"
                        required
                        size="small"
                        variant="outlined"
                        {...field} />}
                    />
                  </Grid>
                  <Grid item xs={12} pb={2}>
                    会社名・部署名
                  </Grid>
                  <Grid item xs={12} sm={12} style={{paddingTop:0}} pb={1}>
                    <Controller
                      name="organization"
                      control={control}
                      render={({ field }) =>  <TextField
                        autoComplete="organization"
                        error={errors.hasOwnProperty("organization")}
                        fullWidth
                        id="organization"
                        label="会社名"
                        variant="outlined"
                        size="small"
                        {...field} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{paddingTop:0}} pb={1}>
                    <Controller
                      name="department"
                      control={control}
                      render={({ field }) =>  <TextField
                        autoComplete="department"
                        error={errors.hasOwnProperty("department")}
                        fullWidth
                        id="department"
                        label="部署名"
                        variant="outlined"
                        size="small"
                        {...field} />}
                    />
                  </Grid>
                  <Grid item xs={12} pb={1}>
                    連絡先
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:0}} pb={2}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                      render={({ field }) => <TextField
                        autoComplete="email"
                        error={errors.hasOwnProperty("email")}
                        fullWidth
                        id="email"
                        label="メールアドレス"
                        required
                        size="small"
                        variant="outlined"
                        {...field} />}
                      />
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:0}}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{ required: true, pattern: /^\d{2,4}-\d{2,4}-\d{4}$|^(0{1}\d{9,10})$|^0[5789]0-[0-9]{4}-[0-9]{4}$|^(050|070|080|090)-\d{4}-\d{4}$/i }}
                      render={({ field }) => <TextField
                        autoComplete="tel"
                        error={errors.hasOwnProperty("phone")}
                        fullWidth
                        helperText="半角で入力してください"
                        id="phone"
                        label="電話番号"
                        required
                        size="small"
                        variant="outlined"
                        {...field} />}
                      />
                  </Grid>
                  <Grid item xs={12} pb={2}>
                    請求先住所
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:0}} pb={2}>
                    <Controller
                      name="billingPostalCode"
                      control={control}
                      rules={{ required: true, pattern: /^\d{3}-?\d{4}$/i }}
                      render={({ field }) => <TextField
                        autoComplete="postal-code"
                        error={errors.hasOwnProperty("billingPostalCode")}
                        fullWidth
                        helperText="半角で入力してください。例: 101-0051"
                        id="billingPostalCode"
                        label="郵便番号"
                        required
                        size="small"
                        sx={{width:"100%", pb:1}}
                        variant="outlined"
                        {...field} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{paddingTop:0}}>
                    <FormControl fullWidth size="small" error={errors.hasOwnProperty("billingPrefecture")}>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="billingPrefecture"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Fragment>
                          <InputLabel id="billingPrefecture-label" required>都道府県</InputLabel>
                          <Select id="billingPrefecture" {...field} autoComplete="address-level1" label="都道府県" labelId="billingPrefecture-label">
                            {prefectures.map((billingPrefecture) => (
                              <MenuItem key={billingPrefecture.value} value={billingPrefecture.value}>{billingPrefecture.label}</MenuItem>
                            ))}
                          </Select></Fragment>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{paddingTop:0}} pb={2}>
                    <Controller
                      name="billingCity"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <TextField
                        autoComplete="address-level2"
                        error={errors.hasOwnProperty("billingCity")}
                        fullWidth
                        helperText="例: 千代田区"
                        id="billingCity"
                        label="市区町村"
                        required
                        size="small"
                        variant="outlined"
                        {...field} />}
                      />
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:0}} pb={1}>
                    <Controller
                      name="billingStreet"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <TextField
                        autoComplete="address-level3"
                        error={errors.hasOwnProperty("billingStreet")}
                        fullWidth
                        helperText="例: 神田神保町1-3-5"
                        id="billingStreet"
                        label="町名以下"
                        required
                        size="small"
                        variant="outlined"
                        {...field} />}
                      />
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:0}} pb={1}>
                    <Controller
                      name="billingBuilding"
                      control={control}
                      render={({ field }) => <TextField
                        autoComplete="address-level4"
                        error={errors.hasOwnProperty("billingBuilding")}
                        fullWidth
                        helperText="例: 日永ビル1F"
                        id="billingBuilding"
                        label="ビル・マンション名"
                        size="small"
                        variant="outlined"
                        {...field} />}
                      />
                  </Grid>

                  <Grid item xs={12} pb={2}>
                    届け先住所
                  </Grid>
                  <Grid item xs={12} style={{paddingTop:0}} pb={2}>
                    <Controller
                      name="sameAsBillingAddress"
                      control={control}
                      render={({ field }) => <FormControlLabel
                        required
                        control={<Checkbox />}
                        id="sameAsBillingAddress"
                        label="「請求先住所」と同一の場合は左記のボックスにチェックを入れてください。"
                        {...field} />}
                    />
                  </Grid>
                  { !sameAsBillingAddress && (
                    <Fragment>
                      <Grid item xs={12} style={{paddingTop:0}} pb={2}>
                        <Controller
                          name="shippingPostalCode"
                          control={control}
                          rules={{ required: true, pattern: /^\d{3}-?\d{4}$/i }}
                          render={({ field }) => <TextField
                            autoComplete="postal-code"
                            error={errors.hasOwnProperty("shippingPostalCode")}
                            fullWidth
                            helperText="半角で入力してください。例: 101-0051"
                            id="shippingPostalCode"
                            label="郵便番号"
                            required
                            size="small"
                            sx={{width:"100%", pb:1}}
                            variant="outlined"
                            {...field} />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={{paddingTop:0}}>
                        <FormControl fullWidth size="small" error={errors.hasOwnProperty("shippingPrefecture")}>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="shippingPrefecture"
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Fragment>
                              <InputLabel id="shippingPrefecture-label" required>都道府県</InputLabel>
                              <Select id="shippingPrefecture" {...field} autoComplete="address-level1" label="都道府県" labelId="shippingPrefecture-label">
                                {prefectures.map((shippingPrefecture) => (
                                  <MenuItem key={shippingPrefecture.value} value={shippingPrefecture.value}>{shippingPrefecture.label}</MenuItem>
                                ))}
                              </Select></Fragment>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{paddingTop:0}} pb={2}>
                        <Controller
                          name="shippingCity"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => <TextField
                            autoComplete="address-level2"
                            error={errors.hasOwnProperty("shippingCity")}
                            fullWidth
                            helperText="例: 千代田区"
                            id="shippingCity"
                            label="市区町村"
                            required
                            size="small"
                            variant="outlined"
                            {...field} />}
                          />
                      </Grid>
                      <Grid item xs={12} style={{paddingTop:0}} pb={1}>
                        <Controller
                          name="shippingStreet"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => <TextField
                            autoComplete="address-level3"
                            error={errors.hasOwnProperty("shippingStreet")}
                            fullWidth
                            helperText="例: 神田神保町1-3-5"
                            id="shippingStreet"
                            label="町名以下"
                            required
                            size="small"
                            variant="outlined"
                            {...field} />}
                          />
                      </Grid>
                      <Grid item xs={12} style={{paddingTop:0}} pb={1}>
                        <Controller
                          name="shippingBuilding"
                          control={control}
                          render={({ field }) => <TextField
                            autoComplete="address-level4"
                            error={errors.hasOwnProperty("shippingBuilding")}
                            fullWidth
                            helperText="例: 日永ビル1F"
                            id="shippingBuilding"
                            label="ビル・マンション名"
                            size="small"
                            variant="outlined"
                            {...field} />}
                          />
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
                <Grid item xs={12} pt={6}>
                  <Box sx={{fontWeight: 'bold'}} xs={12}>
                    お支払い方法
                  </Box>
                </Grid>
                <Grid item xs={12} py={4}>
                  現在、お支払い方法は銀行振込のみ受け付けております。お振込み手数料はご負担くださいますようお願いいたします。価格・納期確定後、お見積書をメールで送付しますので、ご発注のご判断をいただければと思います。
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                sx={{
                  "& .markdown": {
                    py: 3
                  }
                }}>
                <Card sx={{ display: "flex", width: "100%", boxShadow: "none", backgroundColor: "rgb(247, 250, 255)"}}>
                  <CardContent sx={{width:"100%"}}>
                    <Box sx={{fontWeight: 'bold'}} xs={12} pb={4}>
                      見積もり概要
                    </Box>
                    <CartTotalPrice />
                    <Box pt={4}>
                      <Button
                        disabled={isButtonDisabled}
                        onClick={handleSubmit(onSubmit, onError)}
                        variant="contained"
                        disableElevation
                        size="large"
                        style={{width: "100%"}}
                      >
                        見積もり取得
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Container>
        <Divider />
        <Container maxWidth="lg">
          <Footer/>
        </Container>
      </Box>
    )
  }
}

function mapStateToProps({ cart, config, items }) {
  return {
    cart,
    config,
    items
  };
}


export default connect(mapStateToProps)(CheckoutForm);
