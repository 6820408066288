import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import { receiveItem } from "../../../actions/items";
import { addItemToTempCart } from "../../../actions/tempCart";
import { API } from 'aws-amplify';
import { getItemBySku } from '../../../graphql/queries';

const theme = createTheme();

function SubItemTypes({ subItem, items, config }) {
  const language = config.language;
  const [selectedType, setSelectedType] = useState(subItem.types[0].id);
  const [selectedSku, setSelectedSku] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addItemToTempCart({
      id: subItem.id,
      labelNames: subItem.names,
      order: subItem.order,
      sku: null
    }));

    const skus = subItem.types.flatMap(type => type.skus);
    [...new Set(skus)].forEach(sku => {
      API.graphql({
        query: getItemBySku,
        variables: { sku }
      }).then(result => {
        if (result.data.getItemBySku.items.length > 0) {
          dispatch(receiveItem(result.data.getItemBySku.items[0]));
        }
      });
    });
  }, [dispatch, subItem]);

  const handleSelections = (type, sku) => {
    if (type && type !== selectedType) {
      setSelectedType(type);
    }
    setSelectedSku(sku);
    dispatch(addItemToTempCart({
      id: subItem.id,
      labelNames: subItem.names,
      order: subItem.order,
      sku
    }));
  };

  return (
    <Box py={2}>
      <Typography pb={2} variant="h6" style={{ color: "rgba(0,0,0,0.87)" }}>
        {subItem.names[language]}
      </Typography>
      <Typography pb={1} style={{ color: "rgba(0,0,0,0.6)" }}>
        種類
      </Typography>
      {subItem.descriptions[language] && (
        <Typography pb={2} style={{ color: "rgba(0,0,0,0.4)" }} variant="body2">
          {subItem.descriptions[language]}
        </Typography>
      )}
      <TypeSelector types={subItem.types} selectedType={selectedType} onSelect={handleSelections} language={language} />
      <SkuSelector items={items} selectedType={selectedType} selectedSku={selectedSku} skus={subItem.types} onSelect={handleSelections} language={language} />
    </Box>
  );
}

const TypeSelector = ({ types, selectedType, onSelect, language }) => (
  <Grid container spacing={2} pb={2} direction="row">
    {types.map(type => (
      <Grid item key={type.id}>
        <Button
          onClick={() => onSelect(type.id, null)}
          style={{
            color: selectedType === type.id ? theme.primary : "#C5C5C5",
            borderColor: selectedType === type.id ? theme.primary : "#C5C5C5"
          }}
          variant="outlined">
          {type.names[language]}
        </Button>
      </Grid>
    ))}
  </Grid>
);

const SkuSelector = ({ items, selectedType, selectedSku, skus, onSelect, language }) => (
  <Grid container spacing={2} direction="row">
    <Grid item key="none">
      <Button
        onClick={() => onSelect(selectedType, null)}
        style={{
          color: selectedSku ? "#C5C5C5" : theme.primary,
          borderColor: selectedSku ? "#C5C5C5" : theme.primary
        }}
        variant="outlined">
        なし
      </Button>
    </Grid>
    {(skus.find(type => type.id === selectedType) || {}).skus.map(sku => (
      <Grid item key={sku}>
        <Button
          onClick={() => onSelect(selectedType, sku)}
          style={{
            color: selectedSku === sku ? theme.primary : "#C5C5C5",
            borderColor: selectedSku === sku ? theme.primary : "#C5C5C5"
          }}
          variant="outlined"
          disabled={!items[sku]}>
          {items[sku]?.shortNames[language] || sku}
        </Button>
      </Grid>
    ))}
  </Grid>
);

const mapStateToProps = ({ items, config }, { subItem }) => {
  const skus = subItem.types.flatMap(type => type.skus);
  const filteredItems = Object.fromEntries(Object.entries(items).filter(([sku]) => skus.includes(sku)));
  return {
    items: filteredItems,
    config
  };
};

export default connect(mapStateToProps)(SubItemTypes);
