import React, { useState, useEffect } from 'react';

import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';


function CampaignLandscape() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <Grid container maxWidth="xl" spacing={2}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          height: '100%',
          paddingTop: '64px',
        }}
      >
        <Box sx={{
            position: 'fixed',
            top: '-300px',
            left: `${windowWidth-340}px`,
            width: '540px',
            height: '100px',
            backgroundImage: `url('/u.svg')`,
            backgroundPosition: 'right',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '100% 50%',
            zIndex: -1, // Send to back
            display: { sm: 'none', md: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            top: '-475px',
            left: `${windowWidth-740}px`,
            width: '940px',
            height: '100px',
            backgroundImage: `url('/re.svg')`,
            backgroundPosition: 'right',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '100% 50%',
            zIndex: -1, // Send to back
            display: { sm: 'none', md: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            top: '-550px',
            left: `${windowWidth-760}px`,
            width: '860px',
            height: '100px',
            backgroundImage: `url('/ro.svg')`,
            backgroundPosition: 'right',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '100% 50%',
            zIndex: -1, // Send to back
            display: { sm: 'none', md: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            top: '-300px',
            left: `${windowWidth-880}px`,
            width: '540px',
            height: '100px',
            backgroundImage: `url('/u.svg')`,
            backgroundPosition: 'right',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '100% 50%',
            zIndex: -1, // Send to back
            display: { sm: 'none', md: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            top: '-475px',
            left: `${windowWidth-1280}px`,
            width: '940px',
            height: '100px',
            backgroundImage: `url('/re.svg')`,
            backgroundPosition: 'right',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '100% 50%',
            zIndex: -1, // Send to back
            display: { md: 'none', lg: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            top: '-550px',
            left: `${windowWidth-1300}px`,
            width: '860px',
            height: '100px',
            backgroundImage: `url('/ro.svg')`,
            backgroundPosition: 'right',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '100% 50%',
            zIndex: -1, // Send to back
            display: { md: 'none', lg: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed', //fixed
            bottom: '-300px',
            left: '-200px',
            width: '540px',
            height: '100px',
            backgroundImage: `url('/u.svg')`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '25px 0px',
            zIndex: -1, // Send to back
            display: { sm: 'none', md: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            bottom: '-420px',
            left: '-200px',
            width: '940px',
            height: '100px',
            backgroundImage: `url('/re.svg')`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '95px 0px',
            zIndex: -1, // Send to back
            display: { sm: 'none', md: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            bottom: '-540px',
            left: '-200px',
            width: '860px',
            height: '100px',
            backgroundImage: `url('/ro.svg')`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '165px 0px',
            zIndex: -1, // Send to back
            display: { sm: 'none', md: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed', //fixed
            bottom: '-300px',
            left: '360px',
            width: '540px',
            height: '100px',
            backgroundImage: `url('/u.svg')`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '25px 0px',
            zIndex: -1, // Send to back
            display: { sm: 'none', md: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            bottom: '-420px',
            left: '360px',
            width: '940px',
            height: '100px',
            backgroundImage: `url('/re.svg')`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '95px 0px',
            zIndex: -1, // Send to back
            display: { md: 'none', lg: 'block' }
          }}
        />
        <Box sx={{
            position: 'fixed',
            bottom: '-540px',
            left: '360px',
            width: '860px',
            height: '100px',
            backgroundImage: `url('/ro.svg')`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: 'rotate(-45deg)',
            transformOrigin: '165px 0px',
            zIndex: -1, // Send to back
            display: { md: 'none', lg: 'block' }
          }}
        />
        <Grid item xs={7} sx={{ height: '100%', textAlign: 'center'}}>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            sx={{ height: '100%' }}
            style={{ color: 'white' }}
          >
            <Grid item xs={4}
              alignItems="center"
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="end"
            >
              <Typography fontWeight="bold"
                sx={{
                  fontSize: {
                    md: '4vh',
                    lg: '5vh'
                  }}
              }>
                新発売
              </Typography>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                style={{
                  paddingRight: '24px',
                  paddingLeft: '24px'
                }}
              >
                <Typography fontWeight="bold" fontFamily="Dancing Script, cursive" style={{ fontWeight: 700 }}
                  sx={{
                    fontSize: {
                      md: '8vh',
                      lg: '10vh'
                    }}
                  }
                >
                  Catalyst
                </Typography>
                <Typography fontWeight="bold" paddingLeft={4} paddingRight={4}>
                  <CloseIcon
                    sx={{
                      fontSize: {
                        md: '5vh',
                        lg: '6vh'
                      }}
                    }
                  />
                </Typography>
                <Typography fontWeight="bold" fontFamily="Dancing Script, cursive"
                  sx={{
                    fontSize: {
                      md: '8vh',
                      lg: '10vh'
                    }}
                  }
                >
                  Meraki
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}
              alignItems="center"
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Typography style={{ fontWeight: '700' }}
                sx={{
                  fontSize: {
                    md: '3vh',
                    lg: '4vh'
                  }
                }}
              >
                Catalystハードでさらなる高みへ
              </Typography>
              <Typography style={{ fontWeight: '700' }}
                sx={{
                  fontSize: {
                    md: '3vh',
                    lg: '4vh'
                  }
                }}
              >
                さあ、次世代Wi-Fiを体感せよ
              </Typography>
            </Grid>
            <Grid item xs={4}
              alignItems="flex-start"
              display="flex"
              height="100%"
              justifyContent="center"
              pt={4}
              width="100%"
            >
              <Button
                component={RouterLink}
                to={`/search?page=1&keyword=CW91`}
                size="medium"
                variant="outlined"
                disableElevation
                style={{
                  background: 'rgba(255, 255, 255, 0.2)',
                  backdropFilter: 'blur(10px)',
                  borderColor: 'white',
                  borderWidth: '6px',
                  color: 'white',
                  fontWeight: '700',
                  padding: '10px 20px', // Increase button size
                }}
                sx={{
                  fontSize: {
                    md: '3vh',
                    lg: '3vh'
                  }
                }}
              >
                ショップで購入
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <CardMedia
            component="img"
            image="https://openkanal.s3.ap-northeast-1.amazonaws.com/public/campaign1/productImage.png"
            alt="Your Image Description"
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>
      </Grid>
  );
}

export default CampaignLandscape;
