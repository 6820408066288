import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import { receiveItem } from "../../../actions/items";
import { addItemToTempCart } from "../../../actions/tempCart";
import { API } from 'aws-amplify';
import { getItemBySku } from '../../../graphql/queries';

const theme = createTheme();

function SubItemGroups({ subItem, items, config }) {
  const { language } = config;
  const [selectedSkus, setSelectedSkus] = useState({});
  const dispatch = useDispatch();

  const handleSelectSku = (group, sku) => {
    const updatedSkus = {
      ...selectedSkus,
      [group.id]: sku
    };
    setSelectedSkus(updatedSkus);

    const item = {
      id: group.id,
      labelNames: group.names,
      order: subItem.order + group.order / 10,
      sku
    };
    dispatch(addItemToTempCart(item));
  };

  useEffect(() => {
    subItem.groups.forEach((group) => {
      const item = {
        id: group.id,
        labelNames: group.names,
        order: subItem.order + group.order / 10,
        sku: null
      };
      dispatch(addItemToTempCart(item));
    });

    const subItemSkus = subItem.groups.flatMap(group => group.skus);
    const uniqueSkus = new Set(subItemSkus);

    uniqueSkus.forEach((sku) => {
      API.graphql({
        query: getItemBySku,
        variables: { sku }
      }).then((result) => {
        const foundItems = result.data.getItemBySku.items;
        if (foundItems.length > 0) {
          dispatch(receiveItem(foundItems[0]));
        }
      });
    });
  }, [dispatch, subItem]);

  return (
    <Box py={2}>
      <Typography pb={2} variant="h6">{subItem.names[language]}</Typography>
      {subItem.descriptions[language] && (
        <Typography pb={3} variant="body2" color="textSecondary">
          {subItem.descriptions[language]}
        </Typography>
      )}
      {subItem.groups.map((group) => (
        <Group
          key={group.id}
          group={group}
          handleSelectSku={handleSelectSku}
          selectedSkus={selectedSkus}
          items={items}
          language={language}
        />
      ))}
    </Box>
  );
}

function Group({ group, handleSelectSku, selectedSkus, items, language }) {
  return (
    <Box pb={2}>
      <Typography pb={1} color="textSecondary">
        {group.names[language]}
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <StyledButton
            onClick={() => handleSelectSku(group, null)}
            isSelected={selectedSkus[group.id] === null}
          >
            なし
          </StyledButton>
        </Grid>
        {group.skus.map((sku) => (
          <Grid item key={sku}>
            <StyledButton
              onClick={() => handleSelectSku(group, sku)}
              isSelected={selectedSkus[group.id] === sku}
              disabled={!items[sku]}
            >
              {items[sku]?.shortNames[language] || sku}
            </StyledButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function StyledButton({ children, isSelected, ...props }) {
  return (
    <Button
      {...props}
      style={{
        color: isSelected ? theme.palette.primary.main : "#C5C5C5",
        borderColor: isSelected ? theme.palette.primary.main : "#C5C5C5"
      }}
      variant="outlined"
    >
      {children}
    </Button>
  );
}

const mapStateToProps = ({ items, config }, { subItem }) => {
  const subItemSkus = subItem.groups.flatMap(group => group.skus);
  const filteredItems = Object.fromEntries(
    Object.entries(items).filter(([sku]) => subItemSkus.includes(sku))
  );

  return {
    items: filteredItems,
    config
  };
};

export default connect(mapStateToProps)(SubItemGroups);
