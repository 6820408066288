import { connect } from "react-redux";

import Typography from "@mui/material/Typography";
import { Fragment } from "react";


function calculateTotalPrice(listOfItems, itemsInCart) {
  let totalPrice = 0
  // Iterate through all items in tempCart
  Object.keys(itemsInCart).forEach((itemId) => {
    // If the SKU exists in the list of items cached in Redux,
    // then find the price for the current currency and add to totalPrice
    if (listOfItems.hasOwnProperty(itemsInCart[itemId].sku)) {
      totalPrice += listOfItems[itemsInCart[itemId].sku].price
    }
  })
  return totalPrice
}

function TempCartTotalPrice(props) {

  const listOfItems = props.items
  const itemsInCart = props.tempCart.items

  return(
    <Fragment>
      <Typography variant="h4" style={{ color: "rgba(0,0,0,0.87)", display: "contents", fontFamily: 'DIN Alternate' }}>
        {calculateTotalPrice(listOfItems, itemsInCart).toLocaleString("ja-JP")}
      </Typography>
      <Typography variant="h5" style={{ color: "rgba(0,0,0,0.87)", display: "contents" }}>円</Typography>
    </Fragment>
  )
}


function mapStateToProps({ items, tempCart, config }) {
  let itemSkusInTempCart = []
  Object.keys(tempCart.items).forEach(id => {
    if (tempCart.items[id].sku !== null) {
      itemSkusInTempCart.push(tempCart.items[id].sku)
    }
  })
  const filteredItems = itemSkusInTempCart.reduce((obj, sku) => {
                          if (items.hasOwnProperty(sku)) {
                            obj[sku] = items[sku];
                          }
                          return obj;
                        }, {});
  return {
    items: filteredItems,
    tempCart: tempCart,
    config: config
  };
}

  export default connect(mapStateToProps)(TempCartTotalPrice);
