export const RECEIVE_INITIALIZE_SEARCH = "RECEIVE_INITIALIZE_SEARCH";
export const RECEIVE_SEARCH_RESULT_ITEMS = "RECEIVE_SEARCH_RESULT_ITEMS";
export const RECEIVE_SEARCH_FILTER = "RECEIVE_SEARCH_FILTER";
export const RECEIVE_SEARCH_KEYWORD = "RECEIVE_SEARCH_KEYWORD";
export const RECEIVE_SEARCH_PAGE = "RECEIVE_SEARCH_PAGE";
export const REMOVE_SEARCH_RESULT_ITEMS = "REMOVE_SEARCH_RESULT_ITEMS";


export function receiveInitializeSearch() {
  return {
    type: RECEIVE_INITIALIZE_SEARCH
  };
}

export function receiveSearchResultItems(items, nextToken, page, total) {
  return {
    type: RECEIVE_SEARCH_RESULT_ITEMS,
    items,
    nextToken,
    page,
    total
  };
}

export function removeSearchResultItems() {
  return {
    type: REMOVE_SEARCH_RESULT_ITEMS
  }
}

export function receiveSearchFilter(filter) {
  return {
    type: RECEIVE_SEARCH_FILTER,
    filter
  };
}

export function receiveSearchPage(page) {
  return {
    type: RECEIVE_SEARCH_PAGE,
    page
  };
}

export function receiveSearchKeyword(keyword) {
  return {
    type: RECEIVE_SEARCH_KEYWORD,
    keyword
  };
}

