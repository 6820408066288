import React from "react";
import { Link as RouterLink } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";


function Footer(props) {

  return (
    <Box sx={{ flexGrow: 1 }} py={4}>
      <AppBar position="static" elevation={0} color="transparent">
        <Toolbar
          sx={{ justifyContent: "space-between" }}
          style={{ paddingLeft: 0, paddingRight: 0 }}
          color="transparent"
        >
          <Link
            color="text.primary"
            sx={{ flexGrow: 1 }}
            underline="none"
            variant="h6"
          >
          </Link>
          <Link
            component={RouterLink}
            to="/sustainability"
            underline="none"
            color="text.primary"
            pl={4}
          >
            環境保全
          </Link>
          <Link
            component={RouterLink}
            to="/privacy"
            underline="none"
            color="text.primary"
            pl={4}
          >
            個人情報保護方針
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  )
};


export default Footer;
