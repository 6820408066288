import React from "react";
import { createRoot } from 'react-dom/client';

import configureStore from "./configureStore";
import Root from "./components/Root";

import Amplify from '@aws-amplify/core'
import config from './aws-exports'
Amplify.configure(config)

const store = configureStore()

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root store={store}/>);

// render(<Root store={store}/>,
//   document.getElementById("root")
// );
