import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const language = "ja";

function Navigation(props) {
  const pageName = props.pageName;

  return (
    <Box
      sx={{ flexGrow: 1 }}
      py={2.5}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: {
              sm: "flex"
            },
            justifyContent: "space-between"
          }}
        >
          <Box sx={{fontWeight: 'bold'}} xs={12}>{pageName[language]}</Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Navigation;
