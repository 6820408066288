import React from "react";

import { connect } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


function SearchPaginationBar(props) {

  const { search } = props
  const [searchParams, setSearchParams] = useSearchParams({});
  const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1
  const keyword = searchParams.get("keyword")
  const limit = search.limit
  const total = search.total


  function onClickNext(e) {
    if (keyword) {
      setSearchParams({ page: page + 1 , keyword: keyword });
    }
    else {
      setSearchParams({ page: page + 1 });
    }
  }

  function onClickPrevious(e) {
    if (keyword) {
      setSearchParams({ keyword: keyword });
    }
    else {
      setSearchParams({ page: page - 1 });
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        { page > 1 && (
            <Button size="large" startIcon={<ArrowBackIcon />} style={{ marginLeft: 0, paddingLeft: 0, color: "rgba(0, 0, 0, 0.54)"}} onClick={(e) => onClickPrevious(e)}>
              前へ
            </Button>
        )}
      </Grid>
      <Grid item>
        { total > page * limit && (
            <Button size="large" startIcon={<ArrowForwardIcon />} style={{ marginRight: 0, paddingRight: 0, color: "rgba(0, 0, 0, 0.54)"}} onClick={(e) => onClickNext(e)}>
              次へ
            </Button>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps({ search }) {
  return {
    search: search
  };
}

export default connect(mapStateToProps)(SearchPaginationBar);
