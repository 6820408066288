import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";
import ScrollToTop from "../ScrollToTop";


function Privacy() {
  const links = {
    0: {
      name: {
        en: "Privacy Policy",
        ja: "個人情報保護方針"
      }
    }
  };

  return(
    <Box>
      <ScrollToTop />
      <Container maxWidth="lg">
        <Header />
      </Container>
      <CssBaseline />
      <Navigation links={links} />
      <Container maxWidth="lg">
        <Grid container spacing={2} pt={6}>
          <Grid
            item
            sx={{
              "& .markdown": {
                py: 3
              }
            }}
          >
            <Typography sx={{ lineHeight: 1.8 }}>
              OpenKanal合同会社は、個人情報を適切に取り扱うことの重要性を認識し、個人情報保護方針を定め、役員及び従業員が一体となってこれを遵守し、もって個人情報の保護及び個人の権利利益の保護に万全を尽くしてまいります。
            </Typography>
            <List sx={{py:1, pl:4}}>
              <ListItemText sx={{py:1}} primary={`(1) 個人情報を取得するに当たっては、その利用目的をできる限り特定し、その目的の達成に必要な限度において個人情報を取得致します。`} />
              <ListItemText sx={{py:1}} primary={`(2) 個人情報を、本人から直接、書面によって取得する場合には、弊社名、個人情報保護管理者名及び連絡先、利用目的等をお知らせした上で、必要な範囲で個人情報を取得致します。`} />
              <ListItemText sx={{py:1}} primary={`(3) 個人情報の利用は、本人が同意を与えた利用目的の範囲内で行います。また、目的外利用を行わないため、必要な対策を講じる手順を確立し、実施致します。`} />
              <ListItemText sx={{py:1}} primary={`(4) 保有する個人情報を適切な方法で管理し、本人の同意なしに第三者に開示・提供致しません。`} />
              <ListItemText sx={{py:1}} primary={`(5) 保有する個人情報を利用目的に応じた必要な範囲内において、正確、かつ、最新の状態で管理し、個人情報の漏えい、滅失又は毀損などのおそれに対して、合理的な安全対策を講じ、予防並びに是正に努めます。`} />
              <ListItemText sx={{py:1}} primary={`(6) 個人情報の処理を外部へ委託する場合は、漏えいや第三者への提供を行わない等を契約により義務づけ、委託先に対する適切な管理を実施致します。`} />
              <ListItemText sx={{py:1}} primary={`(7) 保有する個人情報についての苦情・相談は、弊社の問合せ窓口に連絡頂くことにより、これに対応致します。`} />
              <ListItemText sx={{py:1}} primary={`(8) 個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。`} />
              <ListItemText sx={{py:1}} primary={`(9) 個人情報保護マネジメントシステムを定め、これを定期的に見直し、継続的に改善致します。`} />
            </List>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="flex-end" py={2}>
          <Grid item >
            <Typography pb={0.5}>
              2023年1月1日 制定日
            </Typography>
          </Grid>
          <Grid item >
            <Typography pb={0.5}>
              OpenKanal合同会社
            </Typography>
          </Grid>
          <Grid item >
            <Typography pb={0.5}>
              代表取締役 小白川諒
            </Typography>
          </Grid>
        </Grid>
        <Grid container pb={6}>
          <Grid item >
            <Typography pb={0.5}>
              個人情報保護方針についてのお問合せ先
            </Typography>
            <Typography pb={0.5}>
              個人情報保護方針に関するお問合せは、
            <Link
              component={RouterLink}
              to="/contact"
              underline="none"
            >
              お問合せ窓口
            </Link>までお願い致します。
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container maxWidth="lg">
        <Footer/>
      </Container>
    </Box>
  )
}

export default Privacy;