import {
  ADD_ITEM_TO_CART,
  UPDATE_ITEM_QUANTITY_IN_CART,
  DELETE_ITEM_IN_CART,
  EMPTY_CART
} from "../actions/cart";

const initialState = {}


export default function cart(state = initialState, action) {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      return {
        ...state,
        [action.id]: {
          sku: action.sku,
          quantity: action.quantity
        }
      }
    case UPDATE_ITEM_QUANTITY_IN_CART:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          quantity: action.quantity
        }
      }
    case DELETE_ITEM_IN_CART:
      const ids = Object.keys(state).filter((id) => id !== action.id)

      if (ids.length > 0) {
        const newState = {}
        ids.forEach((id) => {
          newState[id] = state[id]
        })
        return newState
      } else {
        return initialState
      }
    case EMPTY_CART:
      return initialState
    default:
      return state;
  }
}
