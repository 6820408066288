import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import App from "./App"

const Root = ({ store }) => {

  return (
      <Provider store={store}>
        <Router>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </Router>
      </Provider>
  )
}

export default Root
