import React, { useState, useEffect } from 'react';

import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';


function CampaignPortrait() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const boxes = [
    {type: 'top', top: '-150px', left: `${windowWidth-170}px`, width: '270px', height: '50px', image: '/u.svg', transform: 'rotate(-45deg)', transformOrigin: '100% 50%'},
    {type: 'top', top: '-235px', left: `${windowWidth-370}px`, width: '470px', height: '50px', image: '/re.svg', transform: 'rotate(-45deg)', transformOrigin: '100% 50%'},
    {type: 'top', top: '-225px', left: `${windowWidth-440}px`, width: '430px', height: '50px', image: '/ro.svg', transform: 'rotate(-45deg)', transformOrigin: '100% 50%'},
    {type: 'top', top: '-150px', left: `${windowWidth-440}px`, width: '270px', height: '50px', image: '/u.svg', transform: 'rotate(-45deg)', transformOrigin: '100% 50%'},
    {type: 'top', top: '-235px', left: `${windowWidth-640}px`, width: '470px', height: '50px', image: '/re.svg', transform: 'rotate(-45deg)', transformOrigin: '100% 50%'},
    {type: 'top', top: '-225px', left: `${windowWidth-710}px`, width: '430px', height: '50px', image: '/ro.svg', transform: 'rotate(-45deg)', transformOrigin: '100% 50%'},
    {type: 'bottom', bottom: '-150px', left: '-100px', width: '270px', height: '50px', image: '/u.svg', transform: 'rotate(-45deg)', transformOrigin: '25px 0px'},
    {type: 'bottom', bottom: '-210px', left: '-140px', width: '470px', height: '50px', image: '/re.svg', transform: 'rotate(-45deg)', transformOrigin: '95px 0px'},
    {type: 'bottom', bottom: '-100px', left: '-10px', width: '430px', height: '50px', image: '/ro.svg', transform: 'rotate(-45deg)', transformOrigin: '165px 0px'},
    {type: 'bottom', bottom: '-150px', left: '180px', width: '270px', height: '50px', image: '/u.svg', transform: 'rotate(-45deg)', transformOrigin: '25px 0px'},
    {type: 'bottom', bottom: '-210px', left: '140px', width: '470px', height: '50px', image: '/re.svg', transform: 'rotate(-45deg)', transformOrigin: '95px 0px'},
    {type: 'bottom', bottom: '-150px', left: '230px', width: '430px', height: '50px', image: '/ro.svg', transform: 'rotate(-45deg)', transformOrigin: '165px 0px'}
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <Grid
        container
        alignItems="start"
        maxWidth="xl"
        spacing={2}
        justifyContent="space-around"
        sx={{
          height: '100%',
          paddingTop: '64px',
          mt: {
            xs: 4,
            sm: 7
          }
        }}
        style={{ color: 'white' }}
      >
        {boxes.map((box, i) => {
          if (box.type === 'top') {
            return(
              <Box key={i} sx={{
                position: 'fixed',
                top: box.top,
                left: box.left,
                width: box.width,
                height: box.height,
                backgroundImage: `url('${box.image}')`,
                backgroundPosition: 'right',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                transform: 'rotate(-45deg)',
                transformOrigin: '100% 50%',
                zIndex: -1, // Send to back
                display: { xs: 'block', sm: 'block', md: 'block' }
              }}/>
            )
          }
          else {
            return (
              <Box key={i} sx={{
                position: 'fixed',
                bottom: box.bottom,
                left: box.left,
                width: box.width,
                height: box.height,
                backgroundImage: `url('${box.image}')`,
                backgroundPosition: 'right',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                transform: box.transform,
                transformOrigin: box.transformOrigin,
                zIndex: -1, // Send to back
                display: { xs: 'block', sm: 'block', md: 'block' }
              }}/>
            )
          }
        })}
        <Grid item xs={12}
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="10%"
          justifyContent="end"
        >
          <Typography fontWeight="bold"
            sx={{
              fontSize: {
                xs: '4vh',
                sm: '4vh'
              }}
          }>
            新発売
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center">
            <Typography fontWeight="bold" fontFamily="Dancing Script, cursive" style={{ fontWeight: 700 }}
              sx={{
                fontSize: {
                  xs: '6.5vh',
                  sm: '8vh'
                }}
              }
            >
              Catalyst
            </Typography>
            <Typography fontWeight="bold"
            sx={{
              paddingLeft: {
                xs: 2,
                sm: 4
              },
              paddingRight: {
                xs: 2,
                sm: 4
              }
            }}>
              <CloseIcon
                sx={{
                  fontSize: {
                    xs: '4vh',
                    sm: '5vh'
                  }}
                }
              />
            </Typography>
            <Typography fontWeight="bold" fontFamily="Dancing Script, cursive"
              sx={{
                fontSize: {
                  xs: '6.5vh',
                  sm: '8vh'
                }}
              }
            >
              Meraki
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CardMedia
              component="img"
              image="https://openkanal.s3.ap-northeast-1.amazonaws.com/public/campaign1/productImage.png"
              alt="Your Image Description"
              sx={{
                maxWidth: '40vh',  // 80% of viewport width
                height: 'auto',
                width: 'auto',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="10%"
          justifyContent="center"
        >
          <Typography style={{ fontWeight: '700' }}
            sx={{
              fontSize: {
                xs: '2.5vh',
                sm: '3vh'
              }
            }}
          >
            Catalystハードでさらなる高みへ
          </Typography>
          <Typography style={{ fontWeight: '700' }}
            sx={{
              fontSize: {
                xs: '2.5vh',
                sm: '3vh'
              }
            }}
          >
            さあ、次世代Wi-Fiを体感せよ
          </Typography>
        </Grid>
        <Grid item xs={12}
          alignItems="start"
          display="flex"
          height="30%"
          justifyContent="center"
          width="100%"
          style={{
            paddingTop: '4vh'
          }}

        >
          <Button
            component={RouterLink}
            to={`/search?page=1&keyword=CW91`}
            variant="outlined"
            disableElevation
            style={{
              background: 'rgba(255, 255, 255, 0.2)',
              backdropFilter: 'blur(10px)',
              borderColor: 'white',
              borderWidth: '6px',
              color: 'white',
              padding: '10px 20px', // Increase button size
            }}
            sx={{
              fontSize: {
                xs: '2vh',
                sm: '2vh'
              }
            }}
          >
            ショップで購入
          </Button>
        </Grid>
      </Grid>
  );
}

export default CampaignPortrait;
