import React, {useState, Fragment} from "react";
import { connect } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import SearchIcon from '@mui/icons-material/Search';

import SearchDialog from "./SearchDialog"


function SearchNavigationBar(props) {

  const { search } = props
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }
  const onOpen = () => {
    setOpen(true)
  }
  const total = search.total
  const page = searchParams.get("page") ? searchParams.get("page") : 1
  const start = 1 + (page - 1) * search.limit
  const end = total > start + search.limit - 1 ? start + search.limit - 1 : total

  return (
    <Fragment>
      <Box
        sx={{ flexGrow: 1 }}
      >
        <AppBar elevation={0} position="static" style={{ background: "transparent" }}>
          <Toolbar
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {search.items.length > 0 &&
              <Box style={{color: "rgba(0, 0, 0, 0.54)"}}>検索結果 {search.total} のうち {start}-{end}件</Box>
            }
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="large"
              edge="end"
              aria-label="search"
              onClick={(e) => onOpen()}
            >
              <SearchIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <SearchDialog
        open={open}
        onClose={onClose}
      />
    </Fragment>
  );
}

function mapStateToProps({ search }) {
  return {
    search: search
  };
}

export default connect(mapStateToProps)(SearchNavigationBar);
