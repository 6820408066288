import React from 'react';

import { Route, Routes, useLocation } from "react-router-dom";

import { Helmet } from 'react-helmet-async';

import { createTheme, ThemeProvider } from "@mui/material/styles";

import About from "./About/About";
import Landing from './Landing/Landing';
import Cart from "./Cart/Cart";
import CheckoutForm from "./Checkout/CheckoutForm";
import ContactForm from "./Contact/ContactForm";
import ItemWrapper from "./Item/ItemWrapper";
import Privacy from "./Privacy/Privacy";
import Search from "./Search/Search";

import schema from "../theme/schema.json";
import metadata from './metadata.json'; // Import your metadata
import Sustainability from './Sustainability/Sustainability';

const lightTheme = schema.light;
const theme = createTheme(lightTheme);

function App() {
  const location = useLocation(); // Get current location
  // Fetch metadata based on current path or default to empty strings if not found
  const { title = '', description = '' } = metadata[location.pathname] || {};
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        { title && (
          <title>{title}</title>)}
        { title && (
          <meta property="og:title" content={title} />)}
        { description && (
          <meta name="description" content={description} />)}
        { description && (
          <meta property="og:description" content={description} />)}
        <meta property="og:site_name" content="OpenKanal" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/item/:itemSku" element={<ItemWrapper theme={theme} />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/search" element={<Search />} />
        <Route path="/sustainability" element={<Sustainability />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
