/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      createdAt
      email
      firstName
      firstNamePhonetic
      id
      language
      lastName
      lastNamePhonetic
      message
      phone
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        email
        firstName
        firstNamePhonetic
        id
        language
        lastName
        lastNamePhonetic
        message
        phone
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      cart {
        sku
        name
        quantity
        price
        __typename
      }
      billingBuilding
      billingCity
      billingPostalCode
      billingPrefecture
      billingStreet
      createdAt
      department
      email
      firstName
      firstNamePhonetic
      id
      language
      lastName
      lastNamePhonetic
      organization
      paymentMethod
      phone
      sameAsBillingAddress
      shippingBuilding
      shippingCity
      shippingPostalCode
      shippingPrefecture
      shippingStreet
      updatedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        cart {
          sku
          name
          quantity
          price
          __typename
        }
        billingBuilding
        billingCity
        billingPostalCode
        billingPrefecture
        billingStreet
        createdAt
        department
        email
        firstName
        firstNamePhonetic
        id
        language
        lastName
        lastNamePhonetic
        organization
        paymentMethod
        phone
        sameAsBillingAddress
        shippingBuilding
        shippingCity
        shippingPostalCode
        shippingPrefecture
        shippingStreet
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      categories
      createdAt
      custom
      descriptions {
        en
        ja
        __typename
      }
      id
      images {
        frontShadow
        __typename
      }
      leadTime
      leadTimeUpdatedAt
      names {
        en
        ja
        __typename
      }
      price
      priceUpdatedAt
      region
      searchResultOrder
      searchableText
      shortNames {
        en
        ja
        __typename
      }
      sku
      subItems {
        id
        displayType
        order
        descriptions {
          en
          ja
          __typename
        }
        names {
          en
          ja
          __typename
        }
        subNames {
          en
          ja
          __typename
        }
        skus
        types {
          id
          names {
            en
            ja
            __typename
          }
          skus
          order
          __typename
        }
        groups {
          id
          names {
            en
            ja
            __typename
          }
          skus
          order
          __typename
        }
        __typename
      }
      updatedAt
      vendor
      __typename
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        categories
        createdAt
        custom
        descriptions {
          en
          ja
          __typename
        }
        id
        images {
          frontShadow
          __typename
        }
        leadTime
        leadTimeUpdatedAt
        names {
          en
          ja
          __typename
        }
        price
        priceUpdatedAt
        region
        searchResultOrder
        searchableText
        shortNames {
          en
          ja
          __typename
        }
        sku
        subItems {
          id
          displayType
          order
          descriptions {
            en
            ja
            __typename
          }
          names {
            en
            ja
            __typename
          }
          subNames {
            en
            ja
            __typename
          }
          skus
          types {
            id
            names {
              en
              ja
              __typename
            }
            skus
            order
            __typename
          }
          groups {
            id
            names {
              en
              ja
              __typename
            }
            skus
            order
            __typename
          }
          __typename
        }
        updatedAt
        vendor
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItemBySku = /* GraphQL */ `
  query GetItemBySku(
    $sku: String!
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getItemBySku(
      sku: $sku
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        categories
        createdAt
        custom
        descriptions {
          en
          ja
          __typename
        }
        id
        images {
          frontShadow
          __typename
        }
        leadTime
        leadTimeUpdatedAt
        names {
          en
          ja
          __typename
        }
        price
        priceUpdatedAt
        region
        searchResultOrder
        searchableText
        shortNames {
          en
          ja
          __typename
        }
        sku
        subItems {
          id
          displayType
          order
          descriptions {
            en
            ja
            __typename
          }
          names {
            en
            ja
            __typename
          }
          subNames {
            en
            ja
            __typename
          }
          skus
          types {
            id
            names {
              en
              ja
              __typename
            }
            skus
            order
            __typename
          }
          groups {
            id
            names {
              en
              ja
              __typename
            }
            skus
            order
            __typename
          }
          __typename
        }
        updatedAt
        vendor
        __typename
      }
      nextToken
      __typename
    }
  }
`;
