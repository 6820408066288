import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";

import Footer from "../Footer/Footer";
import Header from "../Header";
import Navigation from "../Navigation";
import ScrollToTop from "../ScrollToTop";


function Sustainability() {
  const links = {
    0: {
      name: {
        en: "Environmental Sustainability",
        ja: "環境保全"
      }
    }
  };

  return(
    <Box>
      <ScrollToTop />
      <Container maxWidth="lg">
        <Header />
      </Container>
      <CssBaseline />
      <Navigation links={links} />
      <Container maxWidth="lg">
        <Grid container spacing={2} pt={6}>
          <Grid
            item
            sx={{
              "& .markdown": {
                py: 3
              }
            }}
          >
            <Typography
              variant="h5"
              pb={4}
              style={{ color: "rgba(0,0,0,0.87)" }}
            >
              方針
            </Typography>
            <Typography sx={{ lineHeight: 1.8 }}>
              OpenKanal合同会社は、環境保全を中心に事業を展開し、持続可能な社会の実現に努めています。2023年1月17日に設立されたこの会社は、事業の拡大と環境保全を両立させることを使命としています。具体的には、完全リモートワークを徹底し、リモート会議を推進することで、温室効果ガス(GHG)の排出削減に寄与しています。今後も、環境を考慮した経営判断を行い、地球環境の保全に貢献していく方針です。
            </Typography>
            <Typography
              variant="h5"
              pt={6}
              pb={4}
              style={{ color: "rgba(0,0,0,0.87)" }}
            >
              宣言
            </Typography>
            <Typography sx={{ lineHeight: 1.8 }}>
              OpenKanal合同会社は、2050年10月31日までにバリューチェーン全体でネットゼロ目標の達成を目指します。
            </Typography>

            <Typography
              variant="h5"
              pt={6}
              pb={4}
              style={{ color: "rgba(0,0,0,0.87)" }}
            >
              目標
            </Typography>
            <Typography pb={2} sx={{ lineHeight: 1.8 }}>
              OpenKanal合同会社は、2050年10月31日までにバリューチェーン全体でネットゼロを目指しています。2024年4月1日から環境保全活動を開始し、以下の初年度目標を設定しました。データを毎年収集し、2050年10月31日までにネットゼロ達成に向けた具体的な目標を継続的に追加する予定です。
            </Typography>
            <TableContainer sx={{pb: 4}}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ minWidth: 140, fontSize: "1rem", fontWeight: "bold" }}>設立日</TableCell>
                    <TableCell sx={{ minWidth: 230, fontSize: "1rem", fontWeight: "bold" }}>部類</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem", fontWeight: "bold"}}>内容</TableCell>
                    <TableCell sx={{ minWidth: 140, fontSize: "1rem", fontWeight: "bold" }}>進捗</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" style={{fontSize: "1rem"}}>2024年4月1日</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>エネルギー/温室効果ガス</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>完全リモートワークを徹底して、スコープ3カテゴリ7のGHG排出量をゼロに維持。</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>進行中</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{fontSize: "1rem"}}>2024年4月1日</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>エネルギー/温室効果ガス</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>出張時には公共交通機関の利用を推奨し、スコープ3カテゴリ6のGHG排出量を削減。2024年4月1日から2025年3月31日までの期間で、全体の50％を公共交通機関の利用へシフトすることを目標とします。</TableCell>
                    <TableCell align="left" style={{fontSize: "1rem"}}>進行中</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container maxWidth="lg">
        <Footer/>
      </Container>
    </Box>
  )
}

export default Sustainability;